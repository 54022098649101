/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth, API, graphqlOperation, Logger } from 'aws-amplify';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Router, navigate } from '@reach/router';
import axios from 'axios';
import moment from 'moment';

import * as subscriptions from '../../graphql/subscriptions';
import { VitalResult, ShowHistory } from './components';

import { formatVitalsData, replaceMissingThreshold } from './defaults';
import getVitalsData from './getVitalsData';
import { nullableObjectPropValidator } from '../../js/localUtils';

import store from '../../_GlobalStateStore/GlobalStateStore';

const logger = new Logger('SceneVitals.js');

const { onCreateAneltoEvents } = subscriptions;

export const getThresholdData = async (setThresholdData) => {
  const formattedThresholdData = {};
  const { payload } = (await Auth.currentSession()).idToken;
  const xmit = payload['custom:xmitId'];
  try {
    const thresholdData = await axios.post(
      'https://o6dy65bp92.execute-api.us-east-1.amazonaws.com/prod',
      {
        xmitId: xmit,
      },
    );

    // create and array of thresholds with metadata
    thresholdData.data.Items.forEach((data) => {
      if (!data.vitalName) return; // skip results that don't have a vital type

      formattedThresholdData[data.vitalName.S] = {
        createdAt: data.createdAt ? data.createdAt.S : null,
        orbId: data.orbId ? data.orbId.S : null,
        localOwner: data.localOwner ? data.localOwner.S : null,
        maximum: data.maximum ? data.maximum.N : null,
        minimum: data.minimum ? data.minimum.N : null,
        thresholdCarePlanId: data.thresholdCarePlanId ? data.thresholdCarePlanId.S : null,
        vitalName: data.vitalName ? data.vitalName.S : null,
        xmitId: data.xmitId ? data.xmitId.S : null,
      };
    });

    // template object to assure no attr key ref is undefined
    const _thresholdData = {
      Oxygen: {},
      BPM: {},
      Systolic: {},
      Diastolic: {},
      Thermometer: {},
      Weight: {},
      Glucose: {},
      ...formattedThresholdData, // existing attr keys+values
    };
    Object.keys(_thresholdData).forEach((keyName) => {
      const minMax = replaceMissingThreshold(
        keyName,
        _thresholdData[keyName]
      );
      _thresholdData[keyName].minimum = minMax.minimum;
      _thresholdData[keyName].maximum = minMax.maximum;
    });
    // logger.debug('formattedThresholdData: ', formattedThresholdData);
    setThresholdData(_thresholdData);
    return _thresholdData;
  } catch (err) {
    logger.debug(err);
    return err;
  }
};

export const createInput = (value, timestamp) => ({
  timestamp: moment.utc(timestamp),
  value: parseFloat(value),
});

const SceneVitals = ({ location, sceneController }) => {
  const [vitalsData, setVitalsData] = useState(undefined);
  const [thresholdData, setThresholdData] = useState(undefined);
  const [isListening, setIsListening] = useState(false);
  const [isNormal, setIsNormal] = useState(undefined);
  const [minThreshold, setMinThreshold] = useState(undefined);
  const [maxThreshold, setMaxThreshold] = useState(undefined);
  const [localCurrentVitalType, setLocalCurrentVitalType] = useState(undefined);

  // eslint-disable-next-line no-unused-vars
  const [aneltoEventSubscription, setAneltoEventSubscription] = useState(undefined);

  const getData = async () => {
    const newPatient = await getVitalsData();
    setVitalsData(newPatient);
  };

  // reset vitals data each time vitalsSelect intent is started - DC
  // useEffect(() => {
  //   if (location.pathname !== '/sceneVitals/takeVital/result') setVitalsData(undefined);
  // }, [location]);

  /**
   * Fetch vitals and threshold data effect
   */
  useEffect(() => {
    getData();
    getThresholdData(setThresholdData);

    return () => {
      setVitalsData(undefined);
      setThresholdData(undefined);
      setLocalCurrentVitalType(undefined);
    };
  }, []);

  /**
   * Sumerian Listeners effect
   */
  useEffect(() => {
    if (!sceneController) return;

    sceneController.sumerian.SystemBus.addListener('tabletState', (payload) => {
      // logger.debug('payload to tabletState: ', payload);
      if (payload === 'tLenable') {
        setIsListening(true);
      } else {
        setIsListening(false);
      }
    });

    // logger.debug('setting showHistory listener in SceneVitals.js');
    sceneController.sumerian.SystemBus.addListener('selectVital', async (payload) => {
      logger.debug("WE MADE IT", "selectVital", payload, vitalsData);
      // payload should be a string like "glucose" here;
      // they map to the VitalTypes slot type in the Vitals Scene lex bot config
      if (!payload) {
        logger.warn('showHistory event passed with null or undefined payload');
        return;
      }
      if (typeof payload !== 'string') {
        logger.warn('showHistory event passed with payload not of type string');
        return;
      }

      setLocalCurrentVitalType(payload);
    });
    sceneController.sumerian.SystemBus.addListener('showHistory', async (payload) => {
      // logger.debug('in showHistory listener, payload: ', payload);

      // payload should be a string like "glucose" here;
      // they map to the VitalTypes slot type in the Vitals Scene lex bot config
      if (!payload) {
        logger.warn('showHistory event passed with null or undefined payload');
        return;
      }
      if (typeof payload !== 'string') {
        logger.warn('showHistory event passed with payload not of type string');
        return;
      }

      setLocalCurrentVitalType(payload);
    });

    // logger.debug('setting showVital listener in VitalResult.js');

    sceneController.sumerian.SystemBus.addListener('showVital', async (payload) => {
      // logger.debug('in showVital listener, payload: ', payload);
      logger.debug("WE MADE IT", "showVital", payload, vitalsData);

      // payload should be a string like "glucose" here;
      // they map to the VitalTypes slot type in the Vitals Scene lex bot config
      if (!payload) {
        logger.warn('showVital event passed with null or undefined payload');
        throw new Error('payload is null or undefined');
      }
      if (typeof payload !== 'string') {
        logger.warn('showVital event passed with payload not of type string');
        throw new Error('payload not of type string');
      }

      setLocalCurrentVitalType(payload);
      navigate('/sceneVitals/takeVital/result');
    });

    // cleanup function
    const cleanup = () => {
      logger.debug('SceneVitals unmounting, removing all listeners on showHistory channel...');
      sceneController.sumerian.SystemBus.removeAllOnChannel('showHistory');
      sceneController.sumerian.SystemBus.removeAllOnChannel('showVital');
      setVitalsData(undefined);
      setLocalCurrentVitalType(undefined);
    };

    return cleanup;
  }, [sceneController]);



  useEffect(() => {
    if (!localCurrentVitalType) return;
    logger.debug("WE MADE IT useEffect", vitalsData);

    let _minThreshold, _maxThreshold;


    setMinThreshold(_minThreshold);
    setMaxThreshold(_maxThreshold);
  }, [localCurrentVitalType]);

  useEffect(() => {
    if (!vitalsData || !sceneController) return;

    const setSubscription = async () => {
      const payload = (await Auth.currentSession()).idToken.payload;
      const xmit = payload['custom:xmitId'];
      const owner = payload['cognito:username'];

      const subscribe = API.graphql(
        graphqlOperation(onCreateAneltoEvents, { Account: xmit }),
      ).subscribe({
        next: (data) => {
          const liveData = formatVitalsData(data);
          logger.debug('LIVE DATA: ', liveData);

          const item = data.value.data.onCreateAneltoEvents;

          switch (liveData.type) {
            case 'blood pressure':
            case 'bloodpressure':
              vitalsData.systolic.push(createInput(item.Systolic, item.TimeStamp));
              vitalsData.diastolic.push(createInput(item.Diastolic, item.TimeStamp));
              break;
            case 'oxygen levels':
            case 'pulseox':
              vitalsData.heartRate.push(createInput(item.BPM, item.TimeStamp));
              vitalsData.oxygen.push(createInput(item.PulseAvg, item.TimeStamp));
              break;
            case 'glucose':
              vitalsData.glucose.push(createInput(item.Value, item.TimeStamp));
              break;
            case 'thermometer':
            case 'temperature':
              vitalsData.temperature.push(createInput(item.Value, item.TimeStamp));
              break;
            case 'weight':
              vitalsData.weight.push(createInput(item.Value, item.TimeStamp));
              break;

            default:
              logger.debug('default case in forEach loop, item: ', item);
          }

          setVitalsData(vitalsData);
          navigate('/sceneVitals/takeVital/result')
        },
      });

      setAneltoEventSubscription(subscribe);
      return subscribe;
    };

    setSubscription();
  }, [sceneController, vitalsData]);

  // cleanup effect to clear subscription
  useEffect(() => {
    if (!aneltoEventSubscription) return;

    return () => aneltoEventSubscription.unsubscribe();
  }, [aneltoEventSubscription]);

  const userData = useStoreState(state => state.user.userData);

  useEffect(() => {
    if(!userData) return;

    const deviceTypeToShortCode = {
      ECG_PROHEALTH_SP: '__airflow__',
      ECG_PROHEALTH_GLUCOSE: '__glucose__',
      ECG_PROHEALTH_BP: '__bloodPressure__',
      ECG_PROHEALTH_WEIGHT: '__weight__',
      ECG_PROHEALTH_PULSE: '__pulseOximeter__',
      ECG_PROHEALTH_TEMP: '__temperature__'
    };

    const deviceButtons = userData.deviceTypes.map((deviceType) => {
      return deviceTypeToShortCode[deviceType];
    });
    logger.debug("deviceButtons: ", deviceButtons);
    window.deviceButtons = JSON.stringify(deviceButtons); //if you think this is awful -- please show me a more elegant way of getting this from here, to lex session attributes. --jkeys 6/3/20


    // store.getActions().eventManager.emit({
    //   channel: 'updateLexAttributes',
    //   data: {
    //     type: 'sessionAttributes',
    //     data: {
    //       deviceButtons: JSON.stringify(deviceButtons)
    //     }
    //   }
    // });
  }, [userData, location]);

  // render
  return (
    <Router>
      <VitalResult
        path="takeVital/result"
        type={localCurrentVitalType}
        data={vitalsData}
        isNormal={isNormal}
        thresholdData={thresholdData}
        minThreshold={minThreshold}
        maxThreshold={maxThreshold}
        isListening={isListening}
        setLocalCurrentVitalType={setLocalCurrentVitalType}
        sceneController={sceneController}
      />
      <ShowHistory
        type={localCurrentVitalType}
        data={vitalsData}
        isNormal={isNormal}
        thresholdData={thresholdData}
        minThreshold={minThreshold}
        maxThreshold={maxThreshold}
        isListening={isListening}
        setLocalCurrentVitalType={setLocalCurrentVitalType}
        sceneController={sceneController}
        path="history/*"
      />
    </Router>
  );
};

SceneVitals.propTypes = {
  sceneController: nullableObjectPropValidator,
};

export default SceneVitals;

//scene vital queries and mutations

export const getUserVitals = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    aneltoEvents {
      items {
        id
        userEventsId
        Account
        BPM
        BPMHigh
        BPMLow
        Battery
        BloodPressure
        BlueId
        DeviceNumber
        Diastolic
        Event
        EventCode
        Glucose
        HeartRate
        Id
        PulseAvg
        PulseHigh
        PulseLow
        PulseOx
        RawData
        Systolic
        Thermometer
        TimeStamp
        Timestamp
        Units
        User
        UserId
        Value
        Vital
        Weight
        owner
        createdAt
      }
      nextToken
    }
  }
}
`;
import React from 'react';
import Icon from '../Icon';
import SFX from '../SFX';
import { Logger } from 'aws-amplify';

const PowerOnElementHandler = (elementAttributes, isHiding /* , handleClick */) => {
  const logger = new Logger('PowerOnElementHandler');
  // General Icon Placement
  let curIcon = Icon(elementAttributes.icon);

  if (curIcon !== undefined) {
    curIcon = (
      <div style={{
        height: 'auto', width: '16%', lineHeight: '10%', margin: '0.8vw', minWidth: '16%',
      }}
      >
        {curIcon}

      </div>
    );
  } else curIcon = (<div style={{ margin: '0.0vw' }} />);

  // let elementText = (<div style={{margin:'auto'}}>{elementAttributes.text}</div>);

  if (!isHiding) {
    // Creation sound effect.
    if (elementAttributes.enterFX != null) {
      logger.debug(elementAttributes.enterFX);
      SFX(elementAttributes.enterFX);
    }
    // else { //Default sfx case
    //   SFX("slidein");
    // }
  }


  switch (elementAttributes.type) {
    case 'outlineSidebar':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            right: '1.8vw',
            top: '11vh',
            width: '26vw',
            height: '72vh',
          }}
        />
      );

    case 'outlinePrivacyButton':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            right: '1vw',
            top: '0vh',
            width: '29.5vw',
            height: '10vh',
          }}
        />
      );

    case 'outlineClipboard':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            left: '31vw',
            top: '53vh',
            width: '17vw',
            height: '24vh',
            // left: '38vw',
            // top: '56vh',
            // width: '17vw',
            // height: '25vh',
          }}
        />
      );

    case 'outlineAssistButton':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            left: '20vw',
            top: '0vh',
            width: '14vw',
            height: '10vh',
          }}
        />
      );

    case 'outlineAddisonButton':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            left: '1vw',
            top: '0vh',
            width: '16.5vw',
            height: '10vh',
          }}
        />
      );

    case 'outlineAddison':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            left: '2vw',
            top: '13vh',
            width: '38vw',
            height: '72vh',
          }}
        />
      );

    case 'outlineNotifications':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            left: '36.7vw',
            top: '0vh',
            width: '27vw',
            height: '10vh',
          }}
        />
      );


    case 'outlineCaptions':
      return (
        <div
          className={
            /* CLASSES */
            `elmOutline elmOnlyOutline elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            left: '2vw',
            top: '84vh',
            width: '93vw',
            height: '13vh',
          }}
        />
      );
    default:
      return undefined;
  }
};

export default PowerOnElementHandler;

/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import {
  Logger, API, Auth, Cache, graphqlOperation,
} from 'aws-amplify';
import { getUser as getUserQuery } from '../custom_graphql/getUser';
import { aneltoEventByAccountTimestamp as getMedHistoryQuery } from '../custom_graphql/getMedHistory';

const logger = new Logger('UserModel');

const model = {

  history: null,
  /**
     * Thunk(side effect) that will update the vitals history with the gql query
     * @param  {fn} actions reducer that will mutate the state
  */
  pullHistory: thunk(async (actions, payload, helpers) => {
    try {
      logger.debug("pullHistory thunk, helpers.getState(): ", helpers.getState())
      const account = helpers.getState().userData.userAccount.account;
      // const account = store.getState().user.userData.userAccount.account
      const results = await API.graphql(graphqlOperation(getMedHistoryQuery, {
        Account: account,
        limit: 50,
        filter: {
          createdAt: {
            contains: new Date().toJSON().toString().slice(0, 7),
          },
        },

      }));
      if (results) actions.pullHistorySuccess(results.data.aneltoEventByAccountTimestamp.items);
    } catch (e) {
      logger.error('[GlobalStateStore] pullVitalsHistory failed', e);
    }
  }),

  /**
     * Action to update vitals model of the store.
     * @param  {obj} state The part of the state tree that the action is against. You can mutate this state value directly as required by the action.
     * @param  {fn} payload The payload, if any, that was provided to the action when it was dispatched.
    */
  pullHistorySuccess: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.history = payload;
  }),
  nextAvailable: 0,
  setNextAvailable: action((state, payload) => {
    state.nextAvailable = payload;
  }),
  userData: null,
  /**
     * Thunk(side effect) that will update the current user data with the getUser gql query
     * @param  {fn} actions reducer that will mutate the state
    */
  pullUserData: thunk(async (actions) => {
    const token = await Auth.currentSession();
    const id = token.idToken.payload['cognito:username'];
    logger.debug('in PullUserData');
    logger.debug('[GlobalStateStore] id =', id);
    try {
      const results = await API.graphql(graphqlOperation(getUserQuery, { id }));

      Cache.setItem('user', results.data.getUser);

      actions.pullUserDataSuccess(results.data.getUser);
      logger.debug('data: ', results);
    } catch (e) {
      logger.warn('[GlobalStateStore] pullUserData failed', e);
    }
    // const getDemographics = (group) => {
    // const token = Auth.currentSession();
    logger.debug('Before options');
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token.idToken.jwtToken,
        id: token.idToken.payload['cognito:username'],
      },
    };
    logger.debug('past Options');
    // fetch customers
    return fetch(
      'https://m4bnyqp68l.execute-api.us-east-1.amazonaws.com/prod/addison',
      options,
    ).then((response) => {
      if (!response.ok) {
        return response.text().then(result => Promise.reject(new Error(result)));
      }
      return response.json().then((data) => {
        logger.debug('getDemographics', data[0].data.contacts);
        window.demographics = data[0].data.contacts;
        return data[0];
      });
    });
    // };
  }),

  /**
     * Action to update userData model of the store.
     * @param  {obj} state The part of the state tree that the action is against. You can mutate this state value directly as required by the action.
     * @param  {fn} payload The payload, if any, that was provided to the action when it was dispatched.
    */
  pullUserDataSuccess: action((state, payload) => {
    state.userData = payload;
  }),
};

export default model;

import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import { typeToPretty } from '../defaults';
const logger = new Logger('GenericTable');
/*******************************************************************************
 * Generic Table
 * 
 * Produces an html table given a set of headings and rows.   
 * 
 * Each object has a custom class, and either a list of items,
 * (if a row) or a value (if a cell).
 * 
 * The custom class may be omitted.
 * 
 * We could use hierarchical css instead of custom classes
 * for most scenarios, but for single-cell control, 
 * custom classes have been left implemented.
 * 
 *========================================
 * heading and row format:
 * 
 * ---------------------------------------
 * headings:
 * {customClass: "", items: []}
 *
 * heading item: 
 * {customClass: "", value: *}
 * 
 * full example:
 * var headings = {customClass: "myHeadingsRow", items: [
 *   {customClass: "", value: 'heading1Value'},
 *   {customClass: "", value: 'heading2Value'},
 * ]}
 * ---------------------------------------
 * rows:
 * { customClass: "", items []}
 * [ //array of rows
 *   {customClass: "", items: []},
 *   {customClass: "", items: []},
 * ]
 * 
 * row item:
 * {customClass: "", value: *}
 * 
 * full example:
 * var rows = [ //array of rows
 *   {customClass: "firstRowClass", items: [
 *     {customClass: "aCustomClass", value: 'value_1-1'},
 *     {customClass: "", value: 'value_1-2'},
 *   ]},
 *   {customClass: "secondRowClass", items: [
 *     {customClass: "", value: 'value_2-1'},
 *     {customClass: "", value: 'value_2-2'},
 *   ]},
 * ]
 * 
 */

/**
 * Cell
 * 
 * Simple, generic table cell for either head or body
 * @param {*} content cell contents 
 * @param {boolean} header True if this is a header cell
 * @param {string} customClass Custom classname for this cell
 */
const Cell = ({
    content,
    header,
    customClass,
  }) => {
    const cellMarkup = header ? (
      <th className={customClass}>
        {content}
      </th>
    ) : (
      <td className={customClass}>
        {content}
      </td>
    );
  
    return (cellMarkup);
  }


const GenericTable = ({ headings, rows }) => {

  const renderHeadingRow = (_cell, cellIndex) => {
    return (
      <Cell
        key={`heading-${cellIndex}`}
        content={headings.items[cellIndex].value}
        header={true}
        customClass={headings.items[cellIndex].customClass || "None"}
      />
    )
  };

  const renderRow = (_row, rowIndex) => {
    return (
      <tr key={`row-${rowIndex}`} className={rows[rowIndex].items.customClass || "Nada"}>
        {rows[rowIndex].items.items.map((_cell, cellIndex) => {
          return (
            <Cell
              key={`${rowIndex}-${cellIndex}`}
              content={rows[rowIndex].items.items[cellIndex].value}
              customClass={rows[rowIndex].items.items[cellIndex].customClass || "None"}
            />
          )
        })}
      </tr>
    )
  };

  // let margin = null;
  const tHeadMarkup = (
    <tr key="heading" className={headings.customClass || "None"}>
      {headings.items.map(renderHeadingRow)}
    </tr>
  );
  const tBodyMarkup = rows.map(renderRow);
  return (
    <table>
        <thead className={headings.customClass}>{tHeadMarkup}</thead>
        <tbody className={rows.customClass}>{tBodyMarkup}</tbody>
    </table>
	// 	<tr> 
	// 		<th>Day, Date</th> 
	// 		<th className="textCenter">Time</th> 
	// 		<th className="textRight">Result</th>
    //         <th></th>
	// 	</tr>
    //     <tr style="color:#F7931E;"> 
	// 		<td>Wed 4 Dec</td> 
	// 		<td className="textRight tableCellWidth6">12:05 PM</td> 
	// 		<td className="textRight">96.5°F</td> 
    //         <td className="tableIcon">
    //             <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z" fill="#F7931E"/></svg></span>
    //         </td>
	// 	</tr>
    //     <tr className="outsideThresholdRow"> {style="color:#F7931E;"}> this color only applies if the vital reading is outside the users threshold
	// 		<td>{dayNamesShort}, {monthNamesShort 4}</td> 
	// 		<td className="textRight tableCellWidth6">{h:ii} {A}</td> 
	// 		<td className="textRight">{96.5}°F</td> 
    //         <td className="tableIcon"> only shows the following icon if the vital reading is outside the users threshold
    //             <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z" fill="#F7931E"/></svg></span>
    //         </td>
	// 	</tr>
        
	// 	<tr> 
	// 		<td>Mon 2 Dec</td> 
	// 		<td className="textRight tableCellWidth6">10:15 AM</td> 
	// 		<td className="textRight">98°F</td>
    //         <td className="tableIcon"></td>
	// 	</tr>
	// 	<tr>
	// 		<td>Sun 1 Dec</td> 
	// 		<td className="textRight tableCellWidth6">11:07 AM</td> 
	// 		<td className="textRight">98.5°F</td>
    //         <td className="tableIcon"></td>
	// 	</tr>
	// 	<tr style="color:#F7931E;"> 
    //         <td>Sat 30 Nov</td> 
	// 		<td className="textRight tableCellWidth6">2:45 PM</td> 
	// 		<td className="textRight">100°F</td> 
    //         <td className="tableIcon">
    //             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z" fill="#F7931E"/></svg>}
    //         </td>
	// 	</tr>
    //     <tr> 
	// 		<td>Thurs 28 Nov</td> 
	// 		<td className="textRight tableCellWidth6">9:29 AM</td> 
	// 		<td className="textRight">98°F</td>
    //         <td className="tableIcon"></td>
	// 	</tr>
	// </table> 
                                           
  );
};

// GenericChart.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object).isRequired,
//   type: PropTypes.string.isRequired,
// };

export default GenericTable;

import React, { useEffect, useState } from "react";
import { Location, Router, navigate } from "@reach/router";
import { Auth, Hub, Logger, XR, API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import { useStoreState, useStoreActions } from "easy-peasy";
import SQS from "aws-sdk/clients/sqs"; // eslint-disable-line import/no-extraneous-dependencies
import { AppScene } from "./containers";
import { ElementManager, SceneVitals, Home, AppMessages } from "./components"; // eslint-disable-line import/named
import { autoCreds } from "./CredentialsModule";
import { scenesConfig } from "./ConfigsModule";
import { setPath, setHomePath, onRouteChange } from "./js";
import store from "./_GlobalStateStore/GlobalStateStore";
import ActivityManager from "./ActivityManager";
import { onCreateMessage } from "./graphql/subscriptions";
import { throttle } from "lodash";
// import MedicationSchedulingFlow from './MedReminderManager';
// import CloudLogger from './CloudLogger';

import "./styles/styles.scss";
import "./App.css";

const logger = new Logger("App");

export const setListeners = (sceneController) => {
  if (!sceneController) {
    logger.debug("no sceneController reference yet");
    return false;
  }

  logger.debug("setting navigateTo listener in App");
  sceneController.sumerian.SystemBus.addListener("navigateTo", (path) => {
    // TODO: regex validate the path to verify we're going to a valid path at the very least
    logger.debug("navigateTo: ", path);
    setPath(path);
  });

  sceneController.sumerian.SystemBus.addListener("getUserId", () => {
    sceneController.sumerian.SystemBus.emit(
      "userID",
      store.getState().user.userData.id
    );
  });

  sceneController.sumerian.SystemBus.addListener("clearMedReminder", () => {
    logger.debug("medreminder to turn off >>>>>>>", "18:00:00");
    store.getActions().medReminders.deactivateReminder("18:00:00");
  });

  sceneController.sumerian.SystemBus.addListener("HideSplashScreen", () => {
    logger.debug(
      "HideSplashScreen signal recieved from SSML, hiding the splash screen now."
    );
    Hub.dispatch("hideSplashScreen");
  });

  return true;
};

// set global state to the customerID and Name according to Auth data
export const setCustomerStateInfo = async () => {
  await autoCreds
    .deploy("app")
    .then(async () => {
      logger.debug("autoCreds deployed");
      // const user = await changecurrentUser(getUser(logger));
      // logger.debug('user', user);
    })
    .catch((err) => {
      logger.error("autoCreds deployment threw an error: ", err);
    });
};

export const requestAudio = async () => {
  await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
};

export const enableAudio = () => {
  XR.onSceneEvent("introScene", "AudioDisabled", () => {
    XR.enableAudio("introScene");
  });

  try {
    XR.enableAudio("introScene");
    XR.setMuted("introScene", false);
  } catch (e) {
    logger.warn("error starting audio, e:", e);
  }
};

requestAudio();

const App = () => {
  const sqsTreeshake = new SQS();
  const [localState, setLocalState] = useState({ sqsTreeshake });

  const currentUser = useStoreState((state) => state.user.userData);
  const initHubDispatchFromSumerianEmit = useStoreActions(
    (actions) => actions.eventManager.initHubDispatchFromSumerianEmit
  );
  const pullUserData = useStoreActions((actions) => actions.user.pullUserData);
  const setSceneController = useStoreActions(
    (actions) => actions.eventManager.setSceneController
  );

  const [sceneController, _setSceneController] = useState(null);

  const [splashHasPlayed, setSplashHasPlayed] = useState(false);
  const [texturesLoaded, setTexturesLoaded] = useState(false);

  useEffect(() => {
    setHomePath(); // set the path back to home at App mount
    window.addEventListener("beforeunload", setHomePath);
    setCustomerStateInfo();

    return () => {
      window.removeEventListener("beforeunload", setHomePath);
    };
  }, []);

  useEffect(() => {
    if (!sceneController) {
      logger.debug("no sceneController reference yet");
      return;
    }

    setListeners(sceneController);

    return () => {
      // eslint-disable-line
      const listeners = [
        "navigateTo",
        "getUserId",
        "clearMedReminder",
        "HideSplashScreen",
      ];
      listeners.forEach((name) =>
        sceneController.sumerian.SystemBus.removeAllOnChannel(name)
      );
    };
  }, [sceneController]);

  const HOME_SCENE_UTTERANCE = "play received message";
  const PLAY_ATTEMPT_DEPLAY = 15000;
  const [currentMessage, setCurrentMessage] = useState(undefined);
  const [messageSubscription, setMessageSubscription] = useState(undefined);

  useEffect(() => {
    if (!sceneController) return;

    const setSubscription = async () => {
      logger.debug("creating message subscription");
      await autoCreds.deploy("App");
      const payload = (await Auth.currentSession()).idToken.payload;
      const userID = payload["cognito:username"];

      logger.debug(
        "await Auth.currentAuthenticatedUser(): ",
        await Auth.currentAuthenticatedUser()
      );
      const subscribe = API.graphql(
        // graphqlOperation(onCreateMessage, { owner: await Auth.currentAuthenticatedUser() }),
        graphqlOperation(onCreateMessage, { owner: userID })
      ).subscribe({
        error: (data) => {
          logger.debug("subscription error: ", data);
        },
        next: (data) => {
          logger.debug("raw sub data: ", data);
          const item = data.value.data.onCreateMessage;
          logger.debug("message received, message obj: ", item);
          const { transcript, priority } = item;

          setCurrentMessage(transcript);
          sceneController.sumerian.SystemBus.emit("updateSessionAttributes", {
            transcript,
          });

          const playMessageHandler = () => {
            // if(currentStatus === IDLE && priority !== SEVERE)
            logger.debug("playMessageHandler invoked");
            navigate("/message");
            setTimeout(
              () =>
                sceneController.sumerian.SystemBus.emit(
                  "post_to_lex",
                  HOME_SCENE_UTTERANCE
                ),
              2000
            );
            // else if(priority === "URGENT")
            //    schedule for end of current activity
            // else
            //    playMessageHandler(); //call recursively until cancelled
          };

          // const playMessage = throttle(playMessageHandler, PLAY_ATTEMPT_DEPLAY);
          // TODO:
          /*

          switch(priority) {
            NORMAL: 
              //play when not busy
            URGENT:
              //play when busy but not in middle of health-related activites
            SEVERE:
              //interrupt current activity with message and play on repeat
          }

          //currentStatus will map to ADDISON_USER_STATE enum from schema.graphql
           while(currentStatus === IDLE) {

          }

          */
          // playMessage();
          // playMessage.cancel(); //TODO: maybe remove

          playMessageHandler();

          sceneController.sumerian.SystemBus.addListener(
            "cancelMessage",
            () => {
              setCurrentMessage(undefined);
              // playMessage.cancel();
            }
          );
        },
      });

      logger.debug("subscribe: ", subscribe);
      setMessageSubscription(subscribe);
      return subscribe;
    };

    setSubscription();

    return () => {
      // messageSubscription && messageSubscription.unsubscribe();
      sceneController.sumerian.SystemBus.removeAllOnChannel("MessageReceived");
    };
  }, [sceneController]);

  const sceneLoaded = async (newSceneController) => {
    try {
      // defer creating the activity manager until we have a reference to the sceneController
      // const activityManager = new ActivityManager();
      // activityManager.putSceneController(newSceneController);
      // setLocalState({ ...localState, activityManager, sceneController: newSceneController });

      setSceneController(newSceneController);
      _setSceneController(newSceneController);
      initHubDispatchFromSumerianEmit();
      enableAudio();

      Hub.dispatch("sceneControllerLoaded", newSceneController);

      try {
        if (!currentUser) await pullUserData();
      } catch (e) {
        logger.warn("error pulling user model");
      }
      logger.debug("currentUser", store.getState().user.userData.id);

      // if this is the first run, go to the calibration and power on scenes
      // else continue to the home scene
      newSceneController.sumerian.SystemBus.addListener(
        "loadInitialScene",
        () => {
          if (
            store.getState().user.userData.firstRun !== null &&
            store.getState().user.userData.firstRun
          ) {
            newSceneController.sumerian.SystemBus.emit(
              "loadSceneByName",
              "PowerOn"
            );
          } else {
            Hub.dispatch("notFirstRun");
            newSceneController.sumerian.SystemBus.emit(
              "loadSceneByName",
              "Home"
            );
          }
        }
      );

      const [user, creds] = await Promise.all([
        Auth.currentSession(),
        Auth.currentCredentials(),
      ]);
      newSceneController.sumerian.SystemBus.emit("TokensGenerated", user);
      newSceneController.sumerian.SystemBus.emit("CredentialsGenerated", creds);

      // MedicationSchedulingFlow(store.getState().user.userData.id);
    } catch (e) {
      logger.error("[APP] sceneLoaded() ERROR", e);
    }
  };
  // use location props in Router - DC
  // render function
  return (
    <>
      <ElementManager />
      <AppScene
        scenesConfig={scenesConfig}
        play
        load
        splashHasPlayed={splashHasPlayed}
        texturesLoaded={texturesLoaded}
        setTexturesLoaded={setTexturesLoaded}
        onLoaded={(controller) => sceneLoaded(controller)}
      />
      <Location>
        {({ location }) => (
          <Router location={location} onChange={onRouteChange}>
            <Home
              path="/"
              location={location}
              sceneController={sceneController}
              hasPlayed={splashHasPlayed}
              setHasPlayed={setSplashHasPlayed}
              texturesLoaded={texturesLoaded}
            />
            <AppMessages
              path="/message"
              currentMessage={currentMessage}
              sceneController={sceneController}
            />
            <SceneVitals
              location={location}
              sceneController={sceneController}
              path="sceneVitals/*"
            />
          </Router>
        )}
      </Location>
    </>
  );
};

export default withAuthenticator(App);

import React, { useState, useEffect } from 'react';
import { Auth, API, Logger, graphqlOperation } from 'aws-amplify';
import { onCreateMessage } from '../../graphql/subscriptions';
// import { navigate } from '@reach/router';
import { throttle } from 'lodash';

const AppMessages = ({ currentMessage }) => {
  const logger = new Logger('AppMessages');
  
  if(!currentMessage) return null;

  return null;
} 

export default AppMessages;
//AppSplashScreen.jsx

import React, { Component } from 'react';
import { Hub, Logger } from 'aws-amplify';

export default class AppSplashScreen extends Component {
  getInitialState() {
    return {
      on: true,
      url: 'https://ecg-addison-assets.s3.amazonaws.com/splash_screen_2019_1624x1080.mp4',
    };
  }

  constructor(props) {
    super(props);
    // this.hasPlayed = props.hasPlayed;
    // this.props = props;
    this.state = this.getInitialState();
    this.logger = new Logger('AppSplashScreen');

    this.getInitialState = this.getInitialState.bind(this);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
  } //end ctor

  toggle() {
    this.setState({ on: !this.state.on }); //toggle
  }

  show() {
    this.setState({ on: true });
  }

  hide() {
    this.setState({ on: false });
  }

  reset() {
    this.setState(this.getInitialState());
  }

  componentDidMount() {
    Hub.listen('sceneLoaded', this.onHubCapsule);
    Hub.listen('IntroSceneLoaded', this.onHubCapsule);
    Hub.listen('hideSplashScreen', this.onHubCapsule);

    let splashVideo = document.getElementById('splash-container');

    splashVideo.onended = event => {
      this.props.setHasPlayed(true);
      Hub.dispatch('splashFinished', {});
    };
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.texturesLoaded && prevProps.hasPlayed && prevState.on)
      setTimeout(() => this.hide(),500);
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    this.hide();

    if (channel === 'hideSplashScreen' || channel === 'IntroSceneLoaded') {
      this.logger.debug('Hiding Splash Screen');
      // setTimeout(this.hide, 2000);
      // this.hide();
    }
  }

  render() {
    // if (this.props.hasPlayed || !this.state.on) return null;

    return (
      <div 
        data-testid="splash-screen-testid" 
        className={`Video-Container ${(this.props.hasPlayed && this.props.texturesLoaded) ? 'fadeOut' : 'fadeIn'} ${(this.state.on) ? '' : 'hidden'}`}
      >
        <video style={{ zIndex: '1' }} id='splash-container' autoPlay muted>
          <source src={this.state.url} type={'video/mp4'} />
        </video>
      </div>
    );
  }
}

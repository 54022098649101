import React from 'react';
import Amplify, { Auth, XR, Hub, Credentials, Logger } from 'aws-amplify'; // eslint-disable-line no-unused-vars
import autobind from 'class-autobind';
// import awsmobile from '../aws-exports';
import AWS from 'aws-sdk/global'; // eslint-disable-line no-unused-vars
import Iot from 'aws-sdk/clients/iot'; // eslint-disable-line no-unused-vars
import IotData from 'aws-sdk/clients/iotdata'; // eslint-disable-line no-unused-vars
import Polly from 'aws-sdk/clients/polly'; // eslint-disable-line no-unused-vars
import LexRuntime from 'aws-sdk/clients/lexruntime'; // eslint-disable-line no-unused-vars
import Lambda from 'aws-sdk/clients/lambda'; // eslint-disable-line no-unused-vars
import store from '../_GlobalStateStore/GlobalStateStore';

import autoCreds, { amplifyConfig } from '../CredentialsModule'; // eslint-disable-line no-unused-vars

const introConfig = require('../config/tlsScene.json');
const introConfigStaging = require('../config/tlsSceneStaging.json');
const introConfigProd = require('../config/tlsSceneProd.json');

const logger = new Logger('AppScene');

const { aws_project_region } = amplifyConfig;

const chooseConfig = () => {
  const { aws_content_delivery_bucket } = amplifyConfig;
  console.log('aws_content_delivery_bucket', aws_content_delivery_bucket);

  if (aws_content_delivery_bucket.includes('-dev') && !aws_content_delivery_bucket.includes('-staging')) {
    return introConfig;
  }
  if (aws_content_delivery_bucket.includes('-staging')) {
    return introConfigStaging;
  }
  if (aws_content_delivery_bucket.includes('-prod')) {
    return introConfigProd;
  }
};

const sceneConfigs = {
  medicationManagementScene: {
    name: 'medicationManagementScene',
    region: aws_project_region,
    events: {
      load: ['loadMedicationManagementScene'],
      play: ['playMedicationManagementScene'],
    },
  },
  introScene: {
    name: 'introScene',
    config: chooseConfig(),
    domId: 'intro-dom-id',
    region: aws_project_region,
    events: {
      load: ['loadIntroScene'],
      play: ['playIntroScene'],
      sumerianplay: [''],
      sumerianLoad: [''],
    },
  }, // end introScene
}; // end sceneConfigs

export default class AppScene extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = this.getInitialState();
    logger.debug('AppScene constructor finished');
  } // end constructor

  getInitialState() {
    return {
      sceneConfig: sceneConfigs.sceneConfig,
      domId: 'sumerian-dom-id',
      sceneName: 'introScene',
      currentSceneName: 'introScene',
      play: false,
    };
  }

  async componentDidMount() {
    autoCreds
      .deploy('appscene')
      .then(amplifyConfig => {
        logger.debug('autoCreds deployed');

        this.state.sceneConfig = chooseConfig();
        this.state.sceneName = 'introScene';
        this.loadAndStartScene();

        Auth.currentUserPoolUser()
          .then(cogUser => {
            const session = cogUser.getSignInUserSession(); // eslint-disable-line no-unused-vars
            new Lambda({ credentials: autoCreds });

            new Iot({
              credentials: autoCreds,
            });
            new IotData({
              endpoint: 'my.host.tld',
              credentials: autoCreds,
            });
            new Polly({
              credentials: autoCreds,
            });
            new LexRuntime({
              credentials: autoCreds,
            });
          })
          .catch(e => {
            // do something
            logger.debug('Failed to set config for AWS SDK');
            logger.debug(e);
          });
      })
      .catch(err => {
        logger.debug('Unable to load scene.', err);
      });
  }

  componentWillUnmount() {
    store
      .getState()
      .eventManager.sceneController.sumerian.SystemBus.removeAllOnChannel('texturesFullyLoaded');
  }

  async loadAndStartScene() {
    logger.debug('in loadAndStartScene, this.state.sceneConfig: ', this.state.sceneConfig);

    await XR.loadScene(this.state.currentSceneName, this.state.domId);
    const controller = XR.getSceneController(this.state.currentSceneName);
    XR.start(this.state.currentSceneName);
    this.props.onLoaded(controller);
    store
      .getState()
      .eventManager.sceneController.sumerian.SystemBus.addListener('texturesFullyLoaded', () =>
        this.props.setTexturesLoaded(true),
      );
  }

  render() {
    logger.debug('rendering AppScene');
    return (
      <div
        data-testid="app-scene-testid"
        className={`App-scene ${
          this.props.texturesLoaded && this.props.splashHasPlayed ? 'fadeIn' : 'fadeOut'
        }`}
      >
        <div id={this.state.domId} className="App-scene" />
      </div>
    );
  }
} // end AppScene

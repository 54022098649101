/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "addisoncarev3-20190906090931-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d23kqma2568zdr.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://ahligu7qhrggti3ynofjojovee.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;

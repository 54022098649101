/**
 * Element Component
 * 
 * @author [Martin Jencka]
 * @author [Michael Lyon]
 */

import React, { useState } from 'react';
// import ReactTimeout from 'react-timeout'
import { Link } from '@reach/router';
import { Logger } from 'aws-amplify'; //eslint-disable-line no-unused-vars

// import _ from 'lodash';
// import { useStoreState, useStoreActions } from 'easy-peasy';
import handleClick from './HandleClick';

// library imports
import AddisonHomeElementHandler from './lib/AddisonHome';
import TopbarElementHandler from './lib/Topbar';
import UtilityElementHandler from './lib/Utility';
import SidebarElementHandler from './lib/Sidebar';
import SceneVitalsElementHandler from './lib/_SceneVitals';
import SceneMedicationManagementElementHandler from './lib/_SceneMedicationManagement';
import SceneActivityMonitoringElementHandler from './lib/_SceneActivityMonitoring';
import SceneMotionAnalysisElementHandler from './lib/_MotionAnalysis';

// Med reminders
import SceneMedRemindersElementHandler from './lib/_SceneMedReminders';
import TableSidebarElementHandler from './lib/TableSidebar';

// Power On
import PowerOnElementHandler from './lib/_PowerOn';

const logger = new Logger('ElementComponent');

const withLink = (comp, elementAttributes) => {
  return (
    <Link to={elementAttributes.to}>
      {comp}
    </Link>
  );
}

const ElementComponent = ({ elementAttributes, isHiding }) => {  

  // super switch statement, elementAttributes.type => component jsx
  let component;

  try {
    // Side Bar Library
    if (!component) component = SidebarElementHandler(elementAttributes, isHiding, handleClick);

    // Utility Library
    if (!component) component = UtilityElementHandler(elementAttributes, isHiding, handleClick);

    // Home (icon) Library
    if (!component) component = AddisonHomeElementHandler(elementAttributes, isHiding, handleClick);

    // Top Bar Library
    if (!component) component = TopbarElementHandler(elementAttributes, isHiding, handleClick);

    // Vitals Scene Library
    if (!component) component = SceneVitalsElementHandler(elementAttributes, isHiding, handleClick);

    // Power On Scene Library
    if (!component) component = PowerOnElementHandler(elementAttributes, isHiding, handleClick);

    // Med Reminders Scene Library
    if (!component) component = SceneMedRemindersElementHandler(elementAttributes, isHiding, handleClick);
    
    // Medication Management Scene Library
    if (!component) component = SceneMedicationManagementElementHandler(elementAttributes, isHiding, handleClick);

    // Table Sidebar
    if (!component) component = TableSidebarElementHandler(elementAttributes, isHiding, handleClick);

   // Activity Monitoring
    if (!component) component = SceneActivityMonitoringElementHandler(elementAttributes, isHiding, handleClick);

    if(!component) component = SceneMotionAnalysisElementHandler(elementAttributes, isHiding, handleClick);

    // if component doesn't exist
    if (!component) {
      component = (
      <button 
        data-testid="element-component-invalid-testid"
        className="elmBtnLarge elmBtnBlue elmAnimPopIn invalidComponent" 
        onClick={() => handleClick(elementAttributes.emit)}
      > 
      {elementAttributes.text} INCORRECT TYPE 
      </button>
        );
    };

    // return component;
  } catch (err) {
    console.log("Error creating ElementComponent, err: ", err);
    logger.debug("Error creating ElementComponent: ", err);
  } finally {
    if (elementAttributes.to) {
      // navigate(`/whateverRoute/`);
      // return component;

      return withLink(component, elementAttributes);
      } else {
      return component;
      }
  }
  // return ElementComponent
};

export default ElementComponent;

import React from 'react';

// Top Bar //
import { ReactComponent as Addison } from './icons/Topbar/Icon_Addison.svg';
import { ReactComponent as Assist } from './icons/Topbar/Icon_Assist.svg';
import { ReactComponent as PrivacyOn } from './icons/Topbar/Icon_PrivacyOn.svg';
import { ReactComponent as PrivacyOff } from './icons/Topbar/Icon_PrivacyOff.svg';
//import { ReactComponent as Clear } from './icons/Topbar/Icon_Clear.svg';
import { ReactComponent as Rain } from './icons/Topbar/Icon_Rain.svg';
import { ReactComponent as Clouds } from './icons/Topbar/Icon_Cloudy.svg';
import { ReactComponent as Snow } from './icons/Topbar/Icon_Snow.svg';
import { ReactComponent as Thunderstorm } from './icons/Topbar/Icon_Thunderstorm.svg';
import { ReactComponent as Drizzle } from './icons/Topbar/Icon_Drizzle.svg';
import { ReactComponent as Atmosphere } from './icons/Topbar/Icon_Atmosphere.svg';
import { ReactComponent as Sun } from './icons/Topbar/Icon_Sun.svg';


// Standard Set
import { ReactComponent as Alarm } from './icons/Standard/Icon_Alarm.svg';
// Yes No
import { ReactComponent as Yes } from './icons/Standard/Icon_Yes.svg';
import { ReactComponent as No } from './icons/Standard/Icon_No.svg';
// Other Standards
import { ReactComponent as Back } from './icons/Standard/Icon_Back.svg';
import { ReactComponent as Cancel } from './icons/Standard/Icon_Cancel.svg';
import { ReactComponent as Done } from './icons/Standard/Icon_Done.svg';
import { ReactComponent as Ready } from './icons/Standard/Icon_ThumbsUp.svg';
import { ReactComponent as NotYet } from './icons/Standard/Icon_NotYet.svg';
import { ReactComponent as None } from './icons/Standard/Icon_None.svg';
import { ReactComponent as Pause } from './icons/Standard/Icon_Pause.svg';
import { ReactComponent as Stop } from './icons/Standard/Icon_Stop.svg';
// Arrows
import { ReactComponent as Up } from './icons/Standard/Icon_Up.svg';
import { ReactComponent as Down } from './icons/Standard/Icon_Down.svg';
import { ReactComponent as Left } from './icons/Standard/Icon_Left.svg';
import { ReactComponent as Right } from './icons/Standard/Icon_Right.svg';
// Sound FX
import { ReactComponent as SoundOff } from './icons/Standard/Icon_SoundOff.svg';
import { ReactComponent as SoundOn } from './icons/Standard/Icon_SoundOn.svg';
// Miscellaneous //
import { ReactComponent as MoreFeatures } from './icons/Standard/Icon_MoreFeatures.svg';
import { ReactComponent as Power } from './icons/Standard/Icon_Power.svg';
import { ReactComponent as Info } from './icons/Standard/Icon_Info.svg';
import { ReactComponent as Replay } from './icons/Standard/Icon_Replay.svg';
import { ReactComponent as Skip } from './icons/Standard/Icon_Skip.svg';
import { ReactComponent as Tutorial } from './icons/Standard/Icon_Tutorial.svg';

// Vitals //
import { ReactComponent as VitalSigns } from './icons/Vitals/Icon_VitalSigns.svg';
import { ReactComponent as VitalsReading } from './icons/Vitals/Icon_VitalsReading.svg';
import { ReactComponent as VitalsHistory } from './icons/Vitals/Icon_VitalsHistory.svg';

// Weight
import { ReactComponent as WeightHistory } from './icons/Vitals/Icon_WeightHistory.svg';
import { ReactComponent as Weight } from './icons/Vitals/Icon_Weight.svg';
import { ReactComponent as WeightScale } from './icons/Vitals/Icon_WeightScale.svg';
import { ReactComponent as WeightReading } from './icons/Vitals/Icon_WeightReading.svg';
// Blood Pressure
import { ReactComponent as BloodPressureHistory } from './icons/Vitals/Icon_BloodPressureHistory.svg';
import { ReactComponent as BloodPressure } from './icons/Vitals/Icon_BloodPressure.svg';
import { ReactComponent as BloodPressureReading } from './icons/Vitals/Icon_BloodPressureReading.svg';
// Pulse Oximeter
import { ReactComponent as PulseOximeterHistory } from './icons/Vitals/Icon_PulseOximeterHistory.svg';
import { ReactComponent as PulseOximeter } from './icons/Vitals/Icon_PulseOximeter.svg';
import { ReactComponent as PulseOximeterReading } from './icons/Vitals/Icon_PulseOximeterReading.svg';
// Glucometer
import { ReactComponent as Glucometer } from './icons/Vitals/Icon_Glucometer.svg';
import { ReactComponent as GlucometerHistory } from './icons/Vitals/Icon_GlucometerHistory.svg';
import { ReactComponent as BloodGlucose } from './icons/Vitals/Icon_BloodGlucose.svg';
// Temperature
import { ReactComponent as Temperature } from './icons/Vitals/Icon_Temperature.svg';
// Spirometer
import { ReactComponent as Spirometer } from './icons/Vitals/Icon_Spirometer.svg';

// Medication //
import { ReactComponent as NextMeds } from './icons/Medication/Icon_NextMedication.svg';
import { ReactComponent as PreviousMeds } from './icons/Medication/Icon_PreviousMedication.svg';
import { ReactComponent as NextMedication } from './icons/Medication/Icon_NextMedication.svg';
import { ReactComponent as PreviousMedication } from './icons/Medication/Icon_PreviousMedication.svg';
import { ReactComponent as TakeNextMedsNow } from './icons/Medication/Icon_TakeNextMedsNow.svg';

// Motion //
import { ReactComponent as Balance } from './icons/Motion/Icon_Balance.svg';
import { ReactComponent as LegCurls } from './icons/Motion/Icon_LegCurls.svg';
import { ReactComponent as LegExtension } from './icons/Motion/Icon_LegExtension.svg';
import { ReactComponent as Motion } from './icons/Motion/Icon_Motion.svg';
import { ReactComponent as MyFitness } from './icons/Motion/Icon_MyFitness.svg';
import { ReactComponent as MyWalkAssessment } from './icons/Motion/Icon_MyWalkAssessment.svg';
import { ReactComponent as StartWalkAssessment } from './icons/Motion/Icon_StartWalkAssessment.svg';

// Scene Labels //
import { ReactComponent as Vitals } from './icons/Vitals/Icon_Vitals.svg';
import { ReactComponent as Medication } from './icons/Medication/Icon_Medication.svg';
import { ReactComponent as Nutrition } from './icons/Icon_Nutrition.svg';
import { ReactComponent as StopSmoking } from './icons/Icon_StopSmoking.svg';
import { ReactComponent as StressCoping } from './icons/Icon_StressCoping.svg';
import { ReactComponent as GaitAnalysis } from './icons/Icon_GaitAnalysis.svg';

// Assist //
import { ReactComponent as PocketMD } from './icons/Assist/Icon_PocketMD.svg';
import { ReactComponent as CustomerSupport } from './icons/Assist/Icon_CustomerSupport.svg';
import { ReactComponent as Emergency } from './icons/Assist/Icon_Emergency.svg';
import { ReactComponent as EmergencyRed } from './icons/Vitals/Icon_EmergencyRed.svg';
import { ReactComponent as Alexa } from './icons/Assist/Icon_Alexa.svg';

// Animals //
import { ReactComponent as FavoritePet } from './icons/Icon_FavoritePet.svg';

// Global //
// A 'Home' import already exists in components/index.js.  using AddisonHome instead
import { ReactComponent as AddisonHome } from './icons/Icon_AddisonHome.svg';

const Icon = name => {
  switch (name) {
    // Top Bar //
    case 'Addison':
      return <Addison />;
    case 'Assist':
      return <Assist />;
    case 'Alexa':
        return <Alexa />;      
    case 'PrivacyOn':
      return <PrivacyOn />;
    case 'PrivacyOff':
      return <PrivacyOff />;
    // case 'Clear':
    //   return <Clear />;      
    case 'Clouds':
      return <Clouds />;  
    case 'Rain':
      return <Rain />;  
    case 'Snow':
      return <Snow />;
    case 'Thunderstorm':
      return <Thunderstorm />;  
    case 'Drizzle':
      return <Drizzle />;  
    case 'Atmosphere':
      return <Atmosphere />;  
    case 'Clear':
      return <Sun />;               

    // Standard Set
    // Side Bar //
    case 'Yes':
      return <Yes />;
    case 'No':
      return <No />;

    case 'Back':
      return <Back />;
    case 'Cancel':
      return <Cancel />;
    case 'Done':
      return <Done />;
    case 'ThumbsUp':
      return <Ready />;
    case 'NotYet':
      return <NotYet />;
    case 'None':
      return <None />;
    case 'Pause':
      return <Pause />;
    case 'Stop':
      return <Stop />;

    case 'Up':
      return <Up />;
    case 'Down':
      return <Down />;
    case 'Left':
      return <Left />;
    case 'Right':
      return <Right />;

    case 'SoundOff':
      return <SoundOff />;
    case 'SoundOn':
      return <SoundOn />;

    case 'MoreFeatures':
      return <MoreFeatures />;
    case 'Power':
      return <Power />;
    case 'Info':
      return <Info />;
    case 'Replay':
      return <Replay />;
    case 'Skip':
      return <Skip />;
    case 'Tutorial':
      return <Tutorial />;

    // Vitals //
    case 'VitalSigns':
      return <VitalSigns />;
    case 'VitalsReading':
      return <VitalsReading />;
    case 'VitalsHistory':
      return <VitalsHistory />;

    case 'WeightHistory':
      return <WeightHistory />;
    case 'WeightScale':
      return <WeightScale />;
    case 'Weight':
      return <Weight />;
    case 'WeightReading':
      return <WeightReading />;

    case 'BloodPressureHistory':
      return <BloodPressureHistory />;
    case 'BloodPressure':
      return <BloodPressure />;
    case 'BloodPressureReading':
      return <BloodPressureReading />;

    case 'PulseOximeterHistory':
      return <PulseOximeterHistory />;
    case 'PulseOximeter':
      return <PulseOximeter />;
    case 'PulseOximeterReading':
      return <PulseOximeterReading />;

    case 'Glucometer':
      return <Glucometer />;
    case 'GlucometerHistory':
      return <GlucometerHistory />;
    case 'BloodGlucose':
      return <BloodGlucose />;

    case 'Temperature':
      return <Temperature />;

      case 'Spirometer':
        return <Spirometer />;
  

    // Medication //
    case 'NextMeds':
      return <NextMeds />;
    case 'PreviousMeds':
      return <PreviousMeds />;
    case 'Alarm':
      return <Alarm />;
    case 'NextMedication':
      return <NextMedication />;
    case 'PreviousMedication':
      return <PreviousMedication />;
    case 'TakeNextMedsNow':
      return <TakeNextMedsNow />;

    // Motion //
    case 'Balance':
      return <Balance />;
    case 'LegCurls':
      return <LegCurls />;
    case 'LegExtensions':
      return <LegExtension />;
    case 'Motion':
      return <Motion />;
    case 'MyFitness':
      return <MyFitness />;
    case 'MyWalkAssessment':
      return <MyWalkAssessment />;
    case 'StartWalkAssessment':
      return <StartWalkAssessment />;

    // Scene Labels //
    case 'Vitals':
      return <Vitals />;
    case 'Medication':
      return <Medication />;
    case 'Nutrition':
      return <Nutrition />;
    case 'StopSmoking':
      return <StopSmoking />;
    case 'StressCoping':
      return <StressCoping />;
    case 'GaitAnalysis':
      return <GaitAnalysis />;

    // Scene Labels //
    case 'PocketMD':
      return <PocketMD />;
    case 'CustomerSupport':
      return <CustomerSupport />;
    case 'Emergency':
      return <Emergency />;
    case 'EmergencyRed':
      return <EmergencyRed />;

    case 'FavoritePet':
      return <FavoritePet />;

    // Global //
    case 'AddisonHome':
      return <AddisonHome />;

    default:
      return undefined;
  }
};

export default Icon;

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Logger } from 'aws-amplify';
import _ from 'lodash';

// import { homeSceneConfig } from '../../config';
import { AppSplashScreen } from '../../containers';
import { nullableObjectPropValidator } from '../../js/localUtils';

const logger = new Logger('Home');

const Home = (props) => {
  const { sceneController, location, hasPlayed, setHasPlayed , texturesLoaded } = props;

  return ( 
    <div data-testid="home-testid" className="App">
      <AppSplashScreen hasPlayed={hasPlayed} setHasPlayed={setHasPlayed} texturesLoaded={texturesLoaded}/>
    </div>
  );
};

Home.propTypes = {
  sceneController: nullableObjectPropValidator,
  location: PropTypes.object,
};

export default Home;

// The font for these things  --  http://patorjk.com/software/taag/#p=display&f=Colossal  --
// The smaller banners are    -- http://patorjk.com/software/taag/#p=display&f=Banner3&t=Type%20Something%20 --
// Other small banner         -- http://patorjk.com/software/taag/#p=display&f=Ivrit&t=NOTIFICATION

import React from 'react';
import Icon from '../Icon.js';
import SFX from '../SFX.js';
import { Logger } from 'aws-amplify';

const AddisonHomeElementHandler = (elementAttributes, isHiding, handleClick) => {
  const logger = new Logger('AddisonHomeElementHandler');
  //General Icon Placement
  let curIcon = Icon(elementAttributes.icon);
  
  if(curIcon !== undefined) curIcon = (<div style={
    {width: '3.2vw',
    marginBottom: '-1.5vh',
    paddingTop: '0.5vh'}
  }>{curIcon}</div>);
  else curIcon = (<div style={{margin:'0.0vw'}}></div>);

  let elementText = (<span style={
    {margin:'auto', 
    display: 'flex',
    color: '#fff',
    fontSize: '3.0vh',
    fontWeight: 'normal',
    fontFamily: 'Open Sans, sans-serif'}
  }>{elementAttributes.text}</span>);

  if(!isHiding){
    //Creation sound effect.
    if (elementAttributes.enterFX != null) {
      logger.debug(elementAttributes.enterFX);
      SFX(elementAttributes.enterFX);
    }
    // else { //Default sfx case
    //   SFX("slidein");
    // }
  }

  switch (elementAttributes.type) {
    
    /**
     * home icon style test #1 (above caption middle-line)
     */
    case "higher":
      return (
        <button 
          className={
            /* CLASSES */
            `elmBtnLarge elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          } 
          style={{
            /* STYLES */
            cursor: 'pointer',
            // border: 'none',
            // backgroundColor: 'transparent',
            // boxShadow: 'none',
            position: 'absolute',
            zIndex: '500000',
            left: '1.8vw',
            top: '85.7vh',
            // width: '9vw',
            // height: '4vw',


            display:'flex',
            justifyContent:'space-around',
            flexDirection:'column',
            alignItems:'center',
            
            padding:'.75vh .25vw',
            height:'10.0vh',
            width:'8vw',
            
            background:'linear-gradient(to bottom,  #553160, #8C4F9F)',
            border:'solid',
            borderRadius:'3vh',
            borderWidth:'3pt',
            borderColor:'#fff',
            boxShadow:'0px 0px 15px #fff',
          }} 
          onClick={() => handleClick(elementAttributes)}
        > 
          {curIcon} {elementText}
        </button>
      );

    /**
     * home icon style test #2 (below caption middle-line)
     */
    case "lower":
      return (
        <button 
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          } 
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            left: '4vw',
            top: '95vh',
            // width: '9vw',
            // height: '5vw',

            display:'flex',
            justifyContent:'space-around',
            flexDirection:'column',
            alignItems:'center',
            
            padding:'.75vh .25vw',
            height:'13vh',
            width:'10vw',
            
            background:'linear-gradient(to bottom,  #553160, #8C4F9F)',
            border:'solid',
            borderRadius:'3vh',
            borderWidth:'3pt',
            borderColor:'#fff',
            boxShadow:'0px 0px 15px #fff',
          }} 
          onClick={() => handleClick(elementAttributes)}
        > 
          {curIcon} {elementText}
        </button>
      );
    default:
      return undefined;
  }
}

export default AddisonHomeElementHandler;
import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import Amplify, {
  API, Auth, Analytics, XR,
} from 'aws-amplify'; // eslint-disable-line no-unused-vars
import * as serviceWorker from './serviceWorker';
// eslint-disable-next-line import/no-named-as-default
import autoCreds from './CredentialsModule';
import { amplifyConfig } from './ConfigsModule';
import GlobalStateStore from './_GlobalStateStore/GlobalStateStore';
import App from './App';

// window.LOG_LEVEL = 'DEBUG';

/*
 * Initiate Auth cred deployment (1 of 2 if not already logged in)
 */

// Auth.signOut();


// eslint-disable-next-line camelcase
const { aws_content_delivery_bucket } = amplifyConfig;

Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig);
API.configure(amplifyConfig);
Analytics.configure(amplifyConfig);
XR.configure(amplifyConfig);


if (aws_content_delivery_bucket.includes('-dev') && !aws_content_delivery_bucket.includes('-staging')) {
  window.ADDISON_ENV = 'dev';
  window.ADDISON_REGION = 'us-east-1';
}
if (aws_content_delivery_bucket.includes('-staging')) {
  window.ADDISON_ENV = 'staging';
  window.ADDISON_REGION = 'us-west-2';
}
if (aws_content_delivery_bucket.includes('-prod')) {
  window.ADDISON_ENV = 'prod';
  window.ADDISON_REGION = 'us-east-1';
}

// Attempt to deploy credentials before rendering if already logged in.
//
// (optional) If not already logged in, set a flag / property stating such
//
const loadingState = { wasLoggedIn: null }; // (optional)

autoCreds.deploy('index')// optional arg is just for debugging Promise wait logic
  .then((conf) => {
    // We were still logged in - creds are up to date - ready to render app
    loadingState.wasLoggedIn = true; // (optional)
  })
  .catch((err) => {
    // We were not logged in - default creds will expire (Amplify bug?)
    // if a service is initiated with them - eg. Analytics.record(<signIn>)
    // and/or if an AWS service object sees prolonged use (over 1hr)
    Analytics.configure({ disabled: true }); // (avoid analytics SignIn bug)

    // Simply states whether or not we were logged in before load
    loadingState.wasLoggedIn = false; // (optional)

    // Make sure to re-enable Analytics after initial login
    // somewhere if disabled above  eg. Analytics.enable();,
    // then send the previously 'ignored' signin event if desired.
  }).finally(() => {
    /*
         * End Init Auth cred deployment (1 of 2 if not already logged in. see App.js)
         */
    ReactDOM.render(
      // eslint-disable-next-line react/jsx-filename-extension
      <StoreProvider store={GlobalStateStore}>
        <App
          loadingState={loadingState}
        />
      </StoreProvider>,
      document.getElementById('root'),
    );
  });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

/**
 * Element Manager
 * 
 * @author [Martin Jencka]
 */

import React from 'react';
import { Hub, Logger } from 'aws-amplify';
import ElementComponent from './ElementComponent';
import ReactTimeout from 'react-timeout'

import store from '../../_GlobalStateStore/GlobalStateStore';

const logger = new Logger('ElementManager');

class ElementManager extends React.Component {

  // * showElement()
  // Formatting for showElement payload. ~ means optional value.
  // If something is simply an element, not a button, likely only id, type, and *maybe* text matters. Use an empty string for the other values
  
  // Payload:
  // - id : The id attached to the button we're showing. This will basically determine what button is attached to what,
  //        and make it easier to change stuff in the future.
  // - text : What does the button say?
  // - type : The button type, out of the list below. This will determine how the button looks, and it's name.
  // - emit : What is the button emitting when we press it? This will usually hook directly into Lex, but as long as something is catching it we can do whatever.
  // ~ optional : A JSON array of optional data. Everything after this will determine the objects that we can assign to.
  //    # sound : What sound does it play, out of the sound list.
  //    # color : Does the button use a custom color? If so, we'll set a color.


  // * hideElement()
  // Works similarly as show button, but you only need to send the ID of the button.



  // * Button Types:
  // Here is a list of all button types we can adjust. This should account for any button in the scene
  // All buttons should be modular and allow for any value, with the exception of *maybe* start and stop listening. That may change.

  // Each of these is structured like this
  // - id : Description. When you're creating a button, the id of the type is the only thing you should worry about.

  // = Top Bar Buttons = (These probably won't need to be changed from defaults, but who knows)
  //  - assist : Assist
  //  - addison : Addison
  //  - listen : Stop Listening / Start Listening (Probably not modular, hardcoded)
  //  - tertiary : tertiary button on top. Used primarily for testing.

  // = Three Button Sidebar =
  //  - sBtn1 : Sidebar Button 1
  //  - sBtn2 : Sidebar Button 2
  //  - sBtn3 : Sidebar Button 3

  // = Yes / No Sidebar =
  //  - sYes : Sidebar Yes
  //  - sNo : Sidebar No

  // = Sidebar Other =
  //  - sDone : Sidebar Done
  //  - sReady : Sidebar Ready (The one with the flag?)


  // * Element Types
  // Elements are functionally the same as buttons, but do not use any onclick events. This may be graphs, tables, graphics, titles, etc.
  
  // = Tables =
  //  - mTable : Medical Table. Used for most tables / Lists
  //  - sChart : Scatter Chart, default addison scatter chart.

  // = Other =
  //  - subtitles : Subtitles. (Only text will be passed to this one.)

  constructor(props) {
    super( props );

    this.state = {
        currentElements: {},
        elements : []
    }

    //Set up for showElement. We're going to connect it to the showElement method, so whenever it hears 'showElement' from the hub it will begin.
    //Ideally, in the future, I'd like to create some sort of object within window where we can access these methods from, to avoid mass emitting.
    //For the moment, this should be just fine.
    Hub.listen('showAmplifyElement', (data) => {
        const { payload } = data;

        if(payload.reload != null){

          for(let i = 0; i < payload.elements.length; i++){

            this.hideElement(payload.elements[i]);
            //this.showElement(payload.elements[i]);
            let delay = 0;
            if(payload.elements[i].delay){
              delay = payload.elements[i].delay;
              //logger.debug("Showing element ", delay);
            }

            //logger.debug("element next", payload.elements[i]);

            this.props.setTimeout(this.showElement, delay, payload.elements[i]);

          }

        }
        else {

          for(let i = 0; i < payload.elements.length; i++){
            this.hideElement(payload.elements[i]);

            //this.showElement(payload.elements[i]);
            let delay = 0;
            if(payload.elements[i].delay){
              delay = payload.elements[i].delay;
              //logger.debug("Showing element ", delay);
            }

            this.props.setTimeout(this.showElement, delay, payload.elements[i]);

          }

        }
    })

    //Hide the element, this only worries about the "Type" of the element.
    Hub.listen('hideAmplifyElement', (data) => {
        const { payload } = data;
        for(let i = 0; i < payload.elements.length; i++){
          this.hideAnimation(payload.elements[i]);
          //this.hideElement(payload.elements[i]);

          //We set a timeout on the hide that way it can animate out before disappearing.
          //Won't take delay yet, in progress.
          this.props.setTimeout(this.hideElement, 250, payload.elements[i]);

        }
    })
  }

  //If we wish to toggle an element, we can do that by calling this function. Very specific use case for this one.
  //Generally you should only use enable and disable unless you know what you're doing,
  //as you may accidentally hide or show what you did not intend otherwise.
  toggleElement(payload){
    let btn = payload;

    for(let i = 0; i < this.state.elements.length; i++){
      if(this.state.elements[i] === btn.type){
        this.hideElement(payload);
        return;
      }
    }

    this.showElement(payload);
  }
  showElement = (payload) => {
    //The first thing we're doing is adding the new button info to the current elements list.
    //logger.debug(payload);
    //Lets assign the data from the payload to this variable. Makes the code more clear.
    let btn = payload;

    //jkeys 6-4-20 -- we need to group the siderbarButton1-6 based on which peripheral types actually exist for an account.
    //therefore, I am creating a new type called "sidebarButtonNextAvailable", and adding logic here to map that to siderbarButton1-6
    //depending on how many device types are present.
    //jkeys 6-4-20 update (moved from Sidebar.js to ElementManager.jsx) --
    //hate to pollute this with button-specific logic, but this is the easiest way to get deviceTypes working.
    //basically, the type is a generic type called siderbarButtonNextAvailable and I need to map it to the correct one --
    //and I have to do it here, because if I wait until ElementComponent.js or Siderbar.js, 
    if(btn.type === 'sidebarButtonNextAvailable') {
      const nextAvailable = store.getState().user.nextAvailable;
      btn.type = `sidebarButton${nextAvailable + 1}`; //next available is 0 based for programmer sanity
      store.getActions().user.setNextAvailable(nextAvailable + 1);
    }

      //We're created some ref variables to update, to keep with no direct mutation.
      let nuElements = this.state.elements;
      let nuCurrentElements = this.state.currentElements;

      //Iterate through the current elements array, and if a button of the current type already exists, we need to remove it.
      for(let i = 0; i < this.state.elements.length; i++){
        if(this.state.elements[i] === btn.type){        
          nuElements.splice(i, 1);
          nuCurrentElements[btn.type] = null; //Add to the elements objects
        }
      }

      nuCurrentElements[btn.type] = btn; //Add to the elements objects
      nuCurrentElements[btn.type].isHiding = false;

      nuElements.push(btn.type); //Add to the type list

      //Set the state.
      this.setState({elements:nuElements, currentElements:nuCurrentElements});
  }

  //Play the hide animation on objects being hidden.
   hideAnimation = (payload) => {
    //Lets assign the data from the payload to this variable. Makes the code more clear.
    let btn = payload;

    //We're created some ref variables to update, to keep with no direct mutation.
    let nuCurrentElements = this.state.currentElements;

    if(nuCurrentElements[btn.type] != null){
      // if(btn.id != null){
      //   if(btn.id != nuCurrentElements[btn.type].id){
      //     logger.debug("Not removing this button " + btn.id);
      //     return;
      //   }
      // }

      nuCurrentElements[btn.type].isHiding = true;
      //Set the state.
      this.setState({currentElements:nuCurrentElements});//, function () {this.props.setTimeout(this.hideElement, 250, btn)});
    } else {
      return;
    }
  }

  //Hide Element Method
  hideElement = (payload) => {

    let btn = payload;

    if(this.state.elements != null){
      let nuElements = this.state.elements;
      let nuCurrentElements = this.state.currentElements;

      if(nuCurrentElements[btn.type] != null){
          // if(btn.id != null){
          //   if(btn.id != nuCurrentElements[btn.type].id){
          //     logger.debug("Not removing this button " + btn.id);
          //     return;
          //   }
          // }
      }
      //Iterate through the current elements array, and if a button of the current type already exists, we need to remove it.
      for(let i = 0; i < this.state.elements.length; i++){
        if(this.state.elements[i] === btn.type){
            nuElements.splice(i, 1);
        }
      }

      nuCurrentElements[btn.type] = null; //Add to the elements objects

      //Set the state.
      this.setState({elements:nuElements, currentElements:nuCurrentElements});
    }
  }


  // //Lets create every button by rendering an Element component for each button that currently exists.
  // renderElements(){
  //   return this.state.elements.map(i => 
  //     (<ElementComponent 
  //       elementAttributes={this.state.currentElements[i]}
  //       isHiding={this.state.currentElements[i].isHiding} 
  //       key={i}
  //     />)
  //   );
  // }

  //This is for handling any key presses in the scene, primarily for debugging.
  handleKeyPress = (event) => {

    // You can hit ` to show an input console, allowing you to debug emits.
    // An emit channel is separated by a tilde from it's payload, since you can only type a string.
    // Format example: "channel~data" for a simple emit
    // You can also emit with an object by building it out.
    // Example "channel~{"data":"inner", "data2":"inner 2"}

    // Here is an input case that will show a button created through this script.
    // Use Example: "showAmplifyElement~{"elements": [ {"text":"Reload Scene", "type":"tertiary", "emit":"reload"} ] }"
    if(event.key === '`'){
      let con = {};
      con.id = "emitField";
      con.text = "emitField";
      con.type = "sEmit";
      con.emit = "button:three";

      this.toggleElement(con);
    }
  }

  componentDidMount(){
    //If we mounted, lets add the debug keypress listener
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount(){
    //On unmount, remove the debug listener.
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  render() {
    return (
        <div>{this.state.elements.map(element =>  //Render every element that currently exists.
          (<ElementComponent 
            elementAttributes={this.state.currentElements[element]}
            isHiding={this.state.currentElements[element].isHiding} 
            key={element}
          />)
        )}</div>
    );
  }
}
export default ReactTimeout(ElementManager)

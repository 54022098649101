/**
 * @author jkeys
 *
 */

import moment from 'moment';
import {
  Auth, API, graphqlOperation, Logger,
} from 'aws-amplify';
import axios from 'axios';
import { getUserVitals } from './graphql/queries';
import { typeFromItem } from './defaults';

const logger = new Logger('getVitalsData');


const getVitalsData = async () => {
  const createInput = (value, timestamp) => ({
    timestamp: moment.utc(timestamp),
    value: parseFloat(value),
  });

  const query = getUserVitals;
  const token = await Auth.currentSession();
  const id = token.idToken.payload['cognito:username'];

  try {
    const results = await API.graphql(graphqlOperation(query, { id }));
    logger.debug('results: ', results);
    // TODO: shape data to desired input for vitals scatter chart

    const aneltoEvents = results.data.getUser.aneltoEvents.items;
    logger.debug('vital events', results.data.getUser.aneltoEvents.items);

    const newPatient = {
      systolic: [],
      diastolic: [],
      glucose: [],
      heartRate: [],
      oxygen: [],
      weight: [],
      temperature: [],
    };

    const sortedVitals = aneltoEvents.sort((a, b) => moment(a.TimeStamp) - moment(b.TimeStamp));
    //aneltoEvents.sort();

    logger.debug('sorted aneltoEvents: ', sortedVitals);

    // for each item, determine which type it is and add it to the new vitalSData object
    aneltoEvents.forEach((item) => {
      const type = typeFromItem(item);

      switch (type) {
        case 'blood pressure':
        case 'bloodpressure':
          newPatient.systolic.push(createInput(item.Systolic, item.TimeStamp));
          newPatient.diastolic.push(createInput(item.Diastolic, item.TimeStamp));
          break;
        case 'oxygen levels':
        case 'pulseox':
          newPatient.heartRate.push(createInput(item.BPM, item.TimeStamp));
          newPatient.oxygen.push(createInput(item.PulseAvg, item.TimeStamp));
          break;

        case 'glucose':
          newPatient.glucose.push(createInput(item.Value, item.TimeStamp));
          break;

        case 'thermometer':
          // newPatient[type].value = item.Thermometer;
          newPatient.temperature.push(createInput(item.Value, item.TimeStamp));
          break;

        case 'weight':
          newPatient.weight.push(createInput(item.Value, item.TimeStamp));
          break;

        default:
          logger.debug('defaut case in forEach loop, item: ', item);
      }
    });

    logger.debug('newPatient: ', newPatient);

    return newPatient;
    // setVitalsData(results);
  } catch (e) {
    logger.warn('error pulling vitals data: ', e);
  }
};

export default getVitalsData;

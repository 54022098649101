import { API, Auth, Logger, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import aws_exports from './aws-exports.js';
import Lambda from 'aws-sdk/clients/lambda';

const logger = new Logger('ActivityManager');

const runAtMidnight = (fn) => {
    const midnight = moment().add(1, 'day');
    midnight.set('hour', 0);
    midnight.set('minute', 0);
    midnight.set('second', 1);

    logger.debug(`midnight: ${midnight}`);

    const msUntilMidnight = midnight.diff(moment());

    setTimeout(fn, msUntilMidnight);
};


const msUntilTime = (time) => {
    // if(!time.hasOwnProperty('isMoment') || !time.isMoment()) {
    //     logger.warn("msUntilTime function called with a non moment object, returning...");
    //     return;
    // }

    //ordering of objects matters, so as a hack,
    //negate the time if the value is negative to put the times back in the correct order
    const msUntilTime = time.diff(moment());

    logger.debug(`${msUntilTime} milliseconds until ${time}`);

    return msUntilTime;
};

export default class ActivityManager {
    constructor() {
        this.momentFormat = "hh:mm:ss";
        // this.momentFormat = "YYYY-MM-DD HH:mm";

        this.state = {
            timeoutInterval: 900000, //15 minutes
            inactivityTimeoutInterval: 14400000, //4 hours
            firstTimeout: null,
            secondTimeout: null,
            inactivityTimeout: null,
            activityDetected: false,
            // morningTimeout: null,
            // eveningTimeout: null,
            activityMonitoringSceneConfig: {
                "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Addison%20Care%20-%20v2/release/authTokens?sceneId=d35302c28c67422294587662b105aa73.scene",
                "sceneId": "d35302c28c67422294587662b105aa73.scene",
                "region": "us-east-1"
            },
            //TODO:
            helpSceneConfig: {

            },
            //create these as functions so they will be re-executed with the current time on every execution
            //this way you don't have to recreate the windows, you just crete the start() and end() methods once and when run at midnight 
            //will always give values for the current (upcoming) day 
            morningActivityWindow: {
                // start: () => moment("05:00:00", this.momentFormat),
                start: () => {
                    const time = moment().startOf('day')
                    time.set('hour', '5');
                    time.set('minute', '0');
                    return time;
                },
                end: () => {
                    const time = moment().startOf('day')
                    time.set('hour', '10');
                    time.set('minute', '0');
                    return time;
                },
            },
            eveningActivityWindow: {
                start: () => {
                    const time = moment().startOf('day')
                    time.set('hour', '17');
                    time.set('minute', '0');
                    return time;
                },
                end: () => {
                    const time = moment().startOf('day')
                    time.set('hour', '22');
                    time.set('minute', '0');
                    return time;
                },
            }
        }

        this.updateActivityWindows();

        logger.debug("morningActivityWindow.start: ", this.state.morningActivityWindow.start());
        logger.debug("morningActivityWindow.end: ", this.state.morningActivityWindow.end());
        logger.debug("eveningActivityWindow.start: ", this.state.eveningActivityWindow.start());
        logger.debug("eveningActivityWindow.end: ", this.state.eveningActivityWindow.end());

        // this.test();
    }

    async updateActivityWindows() {
        try {
            const url = aws_exports.aws_appsync_graphqlEndpoint;
            const session = await Auth.currentSession();
            const { jwtToken, payload } = session.idToken;
            logger.debug("in updateActivityWindows, payload: ", payload);

            const query = `
            query GetUser {
                getUser(id: "${payload["cognito:username"]}") {
                    id
                    userCareProfile {
                        id
                        callWindows {
                            id
                            type
                            startTime
                            endTime
                        }
                    }
                }
            }
        `;

            let data = {
                query
            };

            let headers = {
                'Authorization': jwtToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }

            let config = {
                headers
            }

            let results = await axios.post(url, data, config);
            logger.debug("results.data: ", JSON.stringify(results.data));
            return results;
        } catch (e) {
            logger.warn("error in CreateLexInteraction callback: ", e);
        }
    }

    clearMorningTimeout() {
        clearTimeout(this.state.morningTimeout);
    }


    clearEveningTimeout() {
        clearTimeout(this.state.eveningTimeout);
    }


    initListeners() {


        this.state.sceneController.sumerian.SystemBus.addListener('CreateLexInteraction', async ({ query, input }) => {
            //if morning between 5am and 10am, clear the morning timeout
            this.clearMorningTimeout();

            //if evening between 5pm and 10pm, clear the evening timeout
            this.clearEveningTimeout();

            try {
                const url = aws_exports.aws_appsync_graphqlEndpoint;
                const session = await Auth.currentSession();
                const { jwtToken, payload } = session.accessToken;

                input.owner = payload.username;
                input.lexInteractionUserId = payload.username;
                input.prompt = "stub";

                if (input.slots)
                    input.slots = JSON.stringify(input.slots)
                if (input.requestAttributes)
                    input.requestAttributes = JSON.stringify(input.requestAttributes)
                if (input.sessionAttributes)
                    input.sessionAttributes = JSON.stringify(input.sessionAttributes)

                logger.debug("input to AppSync createLexInteraction: ", input);

                let data = {
                    query,
                    variables: {
                        input
                    }
                };

                let headers = {
                    'Authorization': jwtToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }

                let config = {
                    headers
                }

                let results = await axios.post(url, data, config);
                logger.debug("results.data: ", JSON.stringify(results.data));
                return results;
            } catch (e) {
                logger.warn("error in CreateLexInteraction callback: ", e);
            }
        });
        //listening for tutorial emit to reset firstRun flag
        this.state.sceneController.sumerian.SystemBus.addListener('tutorial', async (capsule) => {

            logger.debug("in reset firstrun: ");
            //attempt to get xmit from token first;
            try {
                const url = aws_exports.aws_appsync_graphqlEndpoint;
                const session = await Auth.currentSession();
                const { jwtToken, payload } = session.idToken;
                logger.debug("in reset firstrun, payload: ", payload);
                const username = payload['cognito:username'];
                let headers = {
                    'Authorization': jwtToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }

                let config = {
                    headers
                }

                const input = {
                    input:
                    {
                        id: username,
                        firstRun: true
                    }
                }
                const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
                    updateUser(input:$input) {
                    id
                    firstRun
                    }}`;
                logger.debug("in reset firstrun graphql: ", username, jwtToken);
                logger.debug("in reset firstrun graphql: ", JSON.stringify(input));
                const query = {
                    query: updateUser,
                    variables: input
                };
                logger.debug("in reset firstrun graphql: ", updateUser);
                // logger.debug(updateUser);
                //const results = await API.graphql(graphqlOperation(url, updateUser, config));
                let results = await axios.post(url, query, config);
                logger.debug(results);
            } catch (err) {
                logger.debug("error: ", err);
            }
        });
        //listening for tutorial emit to reset firstRun flag
        this.state.sceneController.sumerian.SystemBus.addListener('cancelTutorial', async (capsule) => {

            logger.debug("in reset firstrun: ");
            //attempt to get xmit from token first;
            try {
                const url = aws_exports.aws_appsync_graphqlEndpoint;
                const session = await Auth.currentSession();
                const { jwtToken, payload } = session.idToken;
                logger.debug("in reset firstrun, payload: ", payload);
                const username = payload['cognito:username'];
                let headers = {
                    'Authorization': jwtToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }

                let config = {
                    headers
                }

                const input = {
                    input:
                    {
                        id: username,
                        firstRun: false
                    }
                }
                const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
                updateUser(input:$input) {
                id
                firstRun
                }}`;
                logger.debug("in reset firstrun graphql: ", username, jwtToken);
                logger.debug("in reset firstrun graphql: ", JSON.stringify(input));
                const query = {
                    query: updateUser,
                    variables: input
                };
                logger.debug("in reset firstrun graphql: ", updateUser);
                // logger.debug(updateUser);
                //const results = await API.graphql(graphqlOperation(url, updateUser, config));
                let results = await axios.post(url, query, config);
                logger.debug(results);
            } catch (err) {
                logger.debug("error: ", err);
            }
        });

        this.state.sceneController.sumerian.SystemBus.addListener('supportPhone', async (capsule) => {
            logger.debug("Support Phone Type");
            window.phoneContact = "addisonCustomerSupport";
            logger.debug("Setting phone to ECG Customer Support");

        });
        this.state.sceneController.sumerian.SystemBus.addListener('helpPhone', async (capsule) => {
            logger.debug("Help Phone Type");
            window.phoneContact = "addisonHelp";

        });
        this.state.sceneController.sumerian.SystemBus.addListener('pocketmdPhone', async (capsule) => {
            logger.debug("Pocket MD Phone Type");
            window.phoneContact = "addisonPocketMD";

        });

        this.state.sceneController.sumerian.SystemBus.addListener('clearInactivityTimeout', (capsule) => {
            !this.state.inactivityTimeout && logger.warn('event on clearInactivityTimeout channel, but this.state.inactivityTimeout is null or undefined! Bug detected...');

            // this.clearTimeouts();

            this.resetTimeouts();
        });


        // Hub.listen("setActivityTimeout", (capsule) => {
        this.state.sceneController.sumerian.SystemBus.addListener('setActivityTimeout', (capsule) => {
            logger.debug("setActivityTimeout listener called");

            //15 minutes after this timeout is triggered,
            //we can assume that they are inactive and elicit the next timeout slot
            this.state.firstTimeout = setTimeout(() => {
                //I created a new slot for this operation,
                //"Yes_No_Timeout", with the following restricted values (and unlisted synonyms):
                //"yes", "no", "timeout";
                //if "timeout" is elicited, then we programmatically told the lex bot to advance
                //because the user did not respond in time. 
                this.state.sceneController.sumerian.SystemBus.emit('post_to_lex', 'timeout');

                //and set the second 15 minute timeout
                this.state.secondTimeout = setTimeout(() => {
                    //"Yes_No_Timeout" slot
                    this.state.sceneController.sumerian.SystemBus.emit('post_to_lex', 'timeout');

                    //TODO: call responsible party
                    //TODO: remove stub
                    // this.state.eventManager.emit("callResponsibleParty", { responsiblePartyId: "my-responsible-party" })     //TODO: remove stub

                    this.state.sceneController.sumerian.SystemBus.emit("loadScene", this.state.helpSceneConfig);

                }, this.state.timeoutInterval); //15 min second timeout
            }, this.state.timeoutInterval); //15 min first timeout
        });
    }

    putSceneController(sceneController) {
        this.state.sceneController = sceneController;
        this.initListeners();

        this.createMorningInactivityTimeout();
        this.createEveningInactivityTimeout();
        this.scheduleTimersReset();
        // this.postAlarmToRapid(); //TODO: removeme
    }

    /**
     * clear the timeouts if they've been created using clearTimeout
     */
    clearTimeouts() {
        clearTimeout(this.state.firstTimeout);
        clearTimeout(this.state.secondTimeout);
        clearTimeout(this.state.inactivityTimeout);
        clearTimeout(this.state.morningTimeout);
        clearTimeout(this.state.eveningTimeout);
    }

    /**
     * clear the timeouts, then recreate the primary (4+ hr) timeout  
     */
    resetTimeouts() {
        this.clearTimeouts();
        // this.createInactivityTimeout();

    }

    scheduleTimersReset() {
        runAtMidnight(() => {
            this.createMorningInactivityTimeout();
            this.createEveningInactivityTimeout();
            this.scheduleTimersReset();
        })
    }

    loadActivityMonitoringScene() {
        //load the activity monitoring scene
        this.state.sceneController.sumerian.SystemBus.emit("loadScene", this.state.activityMonitoringSceneConfig)

        //and set the first 15 minute timeout           
        this.state.sceneController.sumerian.SystemBus.emit('setActivityTimeout');
        //(yes, i did create a listener and emit to that listener in the same file)
        //(because I can.)
    }

    /**
     * Creates a morning quiet hours timeout.
     */
    createMorningInactivityTimeout() {
        clearTimeout(this.state.morningTimeout);

        const msUntilMorningEnd = msUntilTime(this.state.morningActivityWindow.end());

        if (msUntilMorningEnd < 0) {
            logger.debug("attempting to create a timer for a date that has already passed, returning from createMorningInactivityTimeout");
            return;
        }

        //Maria already wrote this algorithm but I was lazy, from SO:
        //https://stackoverflow.com/questions/26387052/best-way-to-detect-midnight-and-reset-data/26387261#26387261
        //
        //at 10am, disable all timers
        logger.debug("creating morning timeout...");
        this.state.morningTimeout = setTimeout(() => {
            logger.debug("this.state.morningTimeout callback called, this.loadActivityMonitoringScene()...");
            this.loadActivityMonitoringScene();
        }, msUntilMorningEnd);
    }

    /**
     * Creates a morning quiet hours timeout.
     */
    createEveningInactivityTimeout() {
        clearTimeout(this.state.eveningTimeout);

        const msUntilEveningEnd = msUntilTime(this.state.eveningActivityWindow.end());

        if (msUntilEveningEnd < 0) {
            logger.debug("attempting to create a timer for a date that has already passed, returning from createEveningInactivityTimeout");
            return;
        }

        //Maria already wrote this algorithm but I was lazy, from SO:
        //https://stackoverflow.com/questions/26387052/best-way-to-detect-midnight-and-reset-data/26387261#26387261
        //
        //at 10am, disable all timers
        logger.debug("creating evening timeout...");
        this.state.eveningTimeout = setTimeout(() => {
            logger.debug("this.state.eveningTimeout callback called, this.loadActivityMonitoringScene()...");
            this.loadActivityMonitoringScene();
        }, msUntilEveningEnd);
    }

    /*
        isQuietHours(now = moment(moment(), 'hh:mm:ss')) {
            const format = 'hh:mm:ss'
    
            // const isToday = (val) => {
            //     const startOfToday = moment(moment(), 'hh:mm:ss').startOf('day');
            //     const midnightToday = moment(moment(), "hh:mm:ss").endOf('day');
            //     const midnightTomorrow = moment(moment(), "hh:mm:ss").add(1, 'days').endOf('day');
    
            //     if (val.isBetween(startOfToday, midnightToday)) {
            //         return "today";
            //     } else if (val.isBetween(midnightToday, midnightTomorrow)) {
            //         return "tomorrow";
            //     }
            // }
    
            logger.debug("now: ", now);
    
            // var time = moment() gives you current time. no format required.
    
            const morningQuietStart = moment('10:00:00', format); //10 am to
            const morningQuietEnd = moment('17:00:00', format); //5pm
    
            const eveningQuietStart = moment('22:00:00', format); //10pm to        
            const eveningQuietEnd = (moment('5:00:00', format).add(1, 'days')); //5am the following day
    
            return (now.isBetween(morningQuietStart, morningQuietEnd) || now.isBetween(eveningQuietStart, eveningQuietEnd));
        }
    */

    async postAlarmToRapid() {
        let xmit;

        //attempt to get xmit from token first;
        try {
            const session = await Auth.currentSession();
            const { payload } = session.idToken;
            logger.debug("in postAlarmToRapid, payload: ", payload);

            if (payload.hasOwnProperty('custom:xmitId')) {
                xmit = payload['custom:xmitId'];
            } else {
                const username = payload['cognito:username'];

                const query = `
                  query GetXmitByUsername {
                      getUser(id: "${username}") { 
                          id
                          account
                      }
                  }
              `;

                const results = await API.graphql(graphqlOperation(query, {}));

                logger.debug("in postAlarmToRapid, results of query: ", results);


                xmit = results.data.getUser.account;
            }



            const creds = await Auth.currentCredentials();

            const lambda = new Lambda({
                region: 'us-east-1',
                credentials: Auth.essentialCredentials(creds),
            });

            const Payload = JSON.stringify({
                account: xmit,
                signalcode: "GPSI",
                text: "(SOFTWARE TEST) missed activity alert signal",
                time: moment().format('HH:mm:00')
            });

            logger.debug("postAlarmToRapid, payload to rapid: ", Payload);

            const lambdaParams = {
                FunctionName: 'arn:aws:lambda:us-east-1:479226928101:function:POST_Rapid',
                InvocationType: 'RequestResponse',
                Payload,
            };

            const data = await lambda.invoke(lambdaParams).promise();

            logger.debug("result of signal code: ", data);

        } catch (err) {
            logger.debug("error: ", err);
        }




        //if xmit does not exiset in token, query for it by username from AppSync
    }

    /**
     * clear timeout if it exists, then create the primary (4+ hr) timeout  
     */
    createInactivityTimeout() {
        logger.debug("activityManager.createInactivityTimeout called");
        //clear the timeout if it already exists
        clearTimeout(this.state.inactivityTimeout);

        //then create the inactivity timeout
        this.state.inactivityTimeout = setTimeout(() => {
            logger.debug("inactivityTimeout handler called, inactivity detected...");

            //load the activity monitoring scene
            this.state.sceneController.sumerian.SystemBus.emit("loadScene", this.state.activityMonitoringSceneConfig)

            //and set the first 15 minute timeout           
            this.state.sceneController.sumerian.SystemBus.emit('setActivityTimeout');

            this.postAlarmToRapid();
            //(yes, i did create a listener and emit to that listener in the same file)
            //(because I can.)
            // }, 20000);                                                   //20s (for testing)
        }, this.state.inactivityTimeoutInterval);

        return this.state.inactivityTimeout;
    }
}
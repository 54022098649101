// ShowHistory.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'aws-amplify';
import { nullableObjectPropValidator, nullableStringPropValidator } from '../../../js/localUtils';
import TwoResultChart from './TwoResultChart';

import { calculateDomain, normalRanges } from '../defaults';

const logger = new Logger('ShowHistory');

const ShowHistory = ({ type, data, thresholdData, isListening, isNormalResult, setLocalCurrentVitalType, sceneController }) => {
  // lol
  return (
    <TwoResultChart
      type={type}
      data={data}
      thresholdData={thresholdData}
    />
  );
};

ShowHistory.propTypes = {
  type: nullableStringPropValidator,
  data: nullableObjectPropValidator,
  isListening: PropTypes.bool.isRequired,
};

export default ShowHistory;

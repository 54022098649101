const toFahrenheit = kelvin => Math.round((kelvin - 273) * (9 / 5) + 32);

export const getWeather = results => results && results.data;
export const getCondition = weatherResults =>
  weatherResults &&
  weatherResults.weather &&
  weatherResults.weather[0] &&
  weatherResults.weather[0].main;
export const getTemperature = weatherResults =>
  weatherResults &&
  weatherResults.main &&
  weatherResults.main.temp &&
  toFahrenheit(weatherResults.main.temp);

export const getSumerianSystemBus = state => {
  if (
    state &&
    state.eventManager &&
    state.eventManager.sceneController &&
    state.eventManager.sceneController.sumerian &&
    state.eventManager.sceneController.sumerian.SystemBus
  )
    return state.eventManager.sceneController.sumerian.SystemBus;
  else return null;
};

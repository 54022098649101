/**
 * Global State Store
 *
 * @author [Michael Lyon]
 * @author [Isaiah Fielder]
 */

import {
  createStore, computed, action, thunk,
} from 'easy-peasy';
import { Logger } from 'aws-amplify';
import userModel from './models/user';
import eventManagerModel from './models/eventManager';
import medReminders from './models/medReminders';

/**
 * Model of the global state that can be accessed and mutated throughout the application
*/
const model = {
  user: userModel,
  eventManager: eventManagerModel,
  currentVitalType: 'temperature',
  customerVitals: [],
  changeCurrentVitalTypeSuccess: action((state, payload) => {
    state.currentVitalType = payload;
  }),
  medReminders,

  changeCurrentVitalType: thunk(async (actions, payload) => {
    actions.changeCurrentVitalTypeSuccess(payload);
  }),

  isLoadingScene: false,
  isRecording: false,
  toggleIsRecording: action((state) => {
    state.isRecording = !state.isRecording;
  }),
  toggleIsLoadingScene: action((state) => {
    state.isLoadingScene = !state.isLoadingScene;
  }),
  setIsLoadingScene: action((state, payload) => {
    state.isLoadingScene = payload;
  }),

  logger: computed(() => new Logger('[Window] Sumerian')),

};

const preloadedState = {};

/* eslint-disable no-underscore-dangle */
const store = createStore(
  model, preloadedState,
  {
    devTools: true,
  }
);
  /* eslint-enable */

export default store;

export { model };

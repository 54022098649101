export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    owner
    groups
    firstRun
    isDeveloper
    currentScene
    orgName
    orbId
    externalId
    currentState
    userType
    office
    email
    connectPassword
    firstName
    lastName
    address
    dateOfBirth
    deviceTypes
    preferredName
    account
    messages {
      items {
        id
        transcript
      }
    }
  }
}
`;

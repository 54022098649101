import React, { useState } from 'react';

import { Hub, Logger } from 'aws-amplify';
import { useStoreState, /*useStoreActions, debug*/ } from 'easy-peasy';
import ElmFullscreenVideo from '../../../containers/elements/Elm_FullscreenVideo.jsx';

const UtilityElementHandler = (elementAttributes, isHiding, handleClick) => {
  const logger = new Logger('UtilityElementHandler');
  const [debugEmit, setDebugEmit] = useState('');
  
  const sceneController = useStoreState(state => state.eventManager.sceneController);

  const sendEmit = (e) => {
    let channel = e;
    let data = '';
    let msg = '';

    // we're going to split the emit along the channel and data by using any colons.
    if (e.includes('~')) {
      msg = e.split('~');
      channel = msg[0];
      data = msg[1];
    }

    if (data.charAt(0) === '{') {
      data = JSON.parse(data);
    }

    // if it's a sumerian emit, use the $ at the beginning.
    if(channel.charAt(0) === '$') {
      channel = channel.slice(1);
      sceneController.sumerian.SystemBus.emit(channel, data);

    // otherwise, just emit to hub.
    } else {
      Hub.dispatch(channel, data);
    }
  }

  switch (elementAttributes.type) {     

    /**
      * DEBUGGING
      * a sumerian emit field. Used for testing emits to sumerian.
      */
    case "sEmit":
      return (
        <div>
          <form onSubmit={(event) => { event.preventDefault(); logger.debug('debugEmit', debugEmit); sendEmit(debugEmit);}}>
            <input 
              className="elmInputField elmAnimPopIn" 
              style={{
                position: 'absolute',
                zIndex: '500000',
                left: '2vw',
                bottom: '3vh',
                width: '40vw',
                padding: '1vw',
                fontSize: '1.25vw',
                backgroundImage: 'linear-gradient(to bottom, #424242, #424242)',
              }} 
              value={debugEmit} 
              onChange={(event) => setDebugEmit(event.target.value)}
            />
            <button type="submit" value="Submit" 
              className="elmBtnLarge elmBtnBlue elmAnimPopIn" 
              style={{
                position: 'absolute',
                zIndex: '500000',
                left: '45vw',
                bottom: '3vh',
                width: '8vw',
                height: '4vw',
                fontSize: '2vw',
                textAlign: 'center',
                padding: '0vw',
              }} 
              //onClick={() => {logger.debug('debugEmit', debugEmit); sendEmit(debugEmit);}}
            >
              Emit
            </button>
          </form>
        </div>
      );

    /**
     * FULLSCREEN VIDEO
     * -
     */
    case "fullscreenVideo":
      return (
        <ElmFullscreenVideo 
          className={
            /* CLASSES */
            'elmAnimPopIn'
          } 
          url={elementAttributes.link}
        />
      );

      case "listeningBorder":
        return (
          <div 
            className={
              /* CLASSES */
              `elmListeningBorder elmOnlyOutline elmAnimFadeIn
              ${isHiding ? 'elmAnimFadeOut' : ''}`
            } 
            style={{
              /* STYLES */
              position: 'absolute',
              zIndex: '5',
              width: '100%',
              height: '100%',
            }} 
            
          > 
          
          </div>
        );

        case "fade":
            return (
              <div 
                className={
                  /* CLASSES */
                  `elmBlackFill elmAnimFadeInSlow
                  ${isHiding ? 'elmAnimFadeOutSlow' : ''}`
                } 
                style={{
                  /* STYLES */
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }} 
                
              > 
              </div>
            );
    default:
      return undefined;

  }
}

export default UtilityElementHandler;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Icon from '../../_ElementManager/Icon.js';
import moment from 'moment';

import './classNames.scss';

import { useStoreState } from 'easy-peasy';

import { getWeather, getSumerianSystemBus, getCondition, getTemperature } from './selectors';

// --- TODO: Move to AWS Secrets ---
const weatherAPI = {
  url: 'https://api.openweathermap.org/data/2.5/weather',
  key: 'ec409bfc2bb12171fa45b9ddbb822fa0',
  getLoc(lat, lon) {
    return {
      params: {
        lat,
        lon,
        appid: this.key,
      },
    };
  },
};

const fetchWeather = (url, location) => {
  return axios.get(url, location);
};

export default function NotificationBar() {
  const [time, setTime] = useState(moment().format('h:mm A'));
  const [positionData, setPositionData] = useState(null);

  const [weather, setWeather] = useState(null);
  const [temp, setTemp] = useState(null);
  const [condition, setCondition] = useState(null);

  const SystemBus = useStoreState(state => getSumerianSystemBus(state));

  /* --- Sets the time --- */
  /* --- Rerenders component only --- */
  /* --- when the minute updates --- */
  useEffect(() => {
    const timeUpdate = setInterval(() => {
      setTime(moment().format('h:mm A'));
    }, 1000);
    return () => clearInterval(timeUpdate);
  }, []);

  /* --- Sets the position on initial load --- */
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => setPositionData(coords),
        err => {
          console.error(`Unable to retrieve current location for weather! err: ${err}`);
        },
        { maximumAge: 0, enableHighAccuracy: true },
      );
    } else {
      console.error('Location services not enabled in this browser!');
    }
  }, [navigator.geolocation]);

  /* --- Sets the weather --- */
  /* --- based on position --- */
  useEffect(() => {
    if (positionData && !weather) {
      const { latitude, longitude } = positionData;
      const url = weatherAPI.url;
      const location = weatherAPI.getLoc(latitude, longitude);
      fetchWeather(url, location)
        .then(results => {
          const weatherResults = getWeather(results);
          setWeather(weatherResults);
          setCondition(getCondition(weatherResults));
          setTemp(getTemperature(weatherResults));
        })
        .catch(err => console.error(`Unable to fetch weather! Error: ${err}`));
    }
  }, [positionData]);

  /* --- Sets the weather --- */
  /* --- every 15 minutes (900,000 ms) --- */
  /* --- If the weather hasn't changed, --- */
  /* --- the component won't rerender --- */
  useEffect(() => {
    const weatherUpdate = setInterval(() => {
      if (positionData && weather) {
        const { latitude, longitude } = positionData;
        const url = weatherAPI.url;
        const location = weatherAPI.getLoc(latitude, longitude);
        fetchWeather(url, location)
          .then(results => {
            const weatherResults = getWeather(results);
            setWeather(weatherResults);
            setCondition(getCondition(weatherResults));
            setTemp(getTemperature(weatherResults));
          })
          .catch(err => console.error(`Unable to fetch weather! Error: ${err}`));
      }
    }, 900000);
    return () => clearInterval(weatherUpdate);
  }, [positionData, weather]);

  /* --- Emits weather data --- */
  /* --- whenever the weather changes --- */
  useEffect(() => {
    if (weather && SystemBus) SystemBus.emit('weatherRetrieved', weather);
  }, [weather, SystemBus]);

  return (
    <div className="NotificationBar elmNotification elmBtnGhost elmAnimSlideDown">
      {time}
      <div className="NotificationBar_Temperature">
        <div className="NotificationBar_Icon">{(condition && Icon(condition)) || '—'}</div>
        {(temp && ` ${temp}°F`) || '—°F'}
      </div>
    </div>
  );
}

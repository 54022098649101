/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateReminderSchedule = `subscription OnCreateReminderSchedule {
  onCreateReminderSchedule {
    id
    owner
    medicationDetails {
      id
      transcript
      frequency
      lastTaken
      dosageAmount
      dosageUnit
      time
      route
      name
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      day
      createdAt
      updatedAt
    }
    activityDetails {
      id
      transcript
      frequency
      time
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      day
      createdAt
      updatedAt
    }
    vitalDetails {
      id
      transcript
      frequency
      vitalType
      time
      day
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    timestamp
    createdAt
    updatedAt
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateReminderSchedule = `subscription OnUpdateReminderSchedule($owner: String!) {
  onUpdateReminderSchedule(owner: $owner) {
    id
    owner
    medicationDetails {
      id
      transcript
      frequency
      lastTaken
      dosageAmount
      dosageUnit
      time
      route
      name
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      day
      createdAt
      updatedAt
    }
    activityDetails {
      id
      transcript
      frequency
      time
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      day
      createdAt
      updatedAt
    }
    vitalDetails {
      id
      transcript
      frequency
      vitalType
      time
      day
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    timestamp
    createdAt
    updatedAt
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteReminderSchedule = `subscription OnDeleteReminderSchedule {
  onDeleteReminderSchedule {
    id
    owner
    medicationDetails {
      id
      transcript
      frequency
      lastTaken
      dosageAmount
      dosageUnit
      time
      route
      name
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      day
      createdAt
      updatedAt
    }
    activityDetails {
      id
      transcript
      frequency
      time
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      day
      createdAt
      updatedAt
    }
    vitalDetails {
      id
      transcript
      frequency
      vitalType
      time
      day
      note {
        id
        author
        details
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    timestamp
    createdAt
    updatedAt
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateAneltoEvents = `subscription OnCreateAneltoEvents($Account: String!) {
  onCreateAneltoEvents(Account: $Account) {
    id
    userEventsId
    Account
    BPM
    BPMHigh
    BPMLow
    Battery
    BloodPressure
    BlueId
    DeviceNumber
    Diastolic
    Event
    EventCode
    Glucose
    HeartRate
    Id
    PulseAvg
    PulseHigh
    PulseLow
    PulseOx
    RawData
    Systolic
    Thermometer
    TimeStamp
    Timestamp
    Units
    User
    UserId
    Value
    Vital
    Weight
    owner
    createdAt
    type
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateAneltoEvents = `subscription OnUpdateAneltoEvents {
  onUpdateAneltoEvents {
    id
    userEventsId
    Account
    BPM
    BPMHigh
    BPMLow
    Battery
    BloodPressure
    BlueId
    DeviceNumber
    Diastolic
    Event
    EventCode
    Glucose
    HeartRate
    Id
    PulseAvg
    PulseHigh
    PulseLow
    PulseOx
    RawData
    Systolic
    Thermometer
    TimeStamp
    Timestamp
    Units
    User
    UserId
    Value
    Vital
    Weight
    owner
    createdAt
    type
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteAneltoEvents = `subscription OnDeleteAneltoEvents {
  onDeleteAneltoEvents {
    id
    userEventsId
    Account
    BPM
    BPMHigh
    BPMLow
    Battery
    BloodPressure
    BlueId
    DeviceNumber
    Diastolic
    Event
    EventCode
    Glucose
    HeartRate
    Id
    PulseAvg
    PulseHigh
    PulseLow
    PulseOx
    RawData
    Systolic
    Thermometer
    TimeStamp
    Timestamp
    Units
    User
    UserId
    Value
    Vital
    Weight
    owner
    createdAt
    type
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateAneltoPhoneLog = `subscription OnCreateAneltoPhoneLog($Account: String!) {
  onCreateAneltoPhoneLog(Account: $Account) {
    id
    owner
    type
    Account
    Event
    EventCode
    timestamp
    rawData
    details {
      number
      name
      duration
      direction
    }
  }
}
`;
export const onUpdateAneltoPhoneLog = `subscription OnUpdateAneltoPhoneLog {
  onUpdateAneltoPhoneLog {
    id
    owner
    type
    Account
    Event
    EventCode
    timestamp
    rawData
    details {
      number
      name
      duration
      direction
    }
  }
}
`;
export const onDeleteAneltoPhoneLog = `subscription OnDeleteAneltoPhoneLog {
  onDeleteAneltoPhoneLog {
    id
    owner
    type
    Account
    Event
    EventCode
    timestamp
    rawData
    details {
      number
      name
      duration
      direction
    }
  }
}
`;
export const onCreateAneltoAcknowledgement = `subscription OnCreateAneltoAcknowledgement($Account: String!) {
  onCreateAneltoAcknowledgement(Account: $Account) {
    id
    owner
    Account
    Event
    EventCode
    timestamp
    rawData
  }
}
`;
export const onUpdateAneltoAcknowledgement = `subscription OnUpdateAneltoAcknowledgement {
  onUpdateAneltoAcknowledgement {
    id
    owner
    Account
    Event
    EventCode
    timestamp
    rawData
  }
}
`;
export const onDeleteAneltoAcknowledgement = `subscription OnDeleteAneltoAcknowledgement {
  onDeleteAneltoAcknowledgement {
    id
    owner
    Account
    Event
    EventCode
    timestamp
    rawData
  }
}
`;
export const onCreateRapidEvent = `subscription OnCreateRapidEvent($Account: String!) {
  onCreateRapidEvent(Account: $Account) {
    id
    owner
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
    Longitude
    Latitude
  }
}
`;
export const onUpdateRapidEvent = `subscription OnUpdateRapidEvent {
  onUpdateRapidEvent {
    id
    owner
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
    Longitude
    Latitude
  }
}
`;
export const onDeleteRapidEvent = `subscription OnDeleteRapidEvent {
  onDeleteRapidEvent {
    id
    owner
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
    Longitude
    Latitude
  }
}
`;
export const onCreateUser = `subscription OnCreateUser($owner: String) {
  onCreateUser(owner: $owner) {
    id
    owner
    messages {
      items {
        id
        owner
        transcript
        priority
        userID
        xmit
        messageUserId
        createdAt
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    groups
    firstRun
    isDeveloper
    currentScene
    orgName
    orbId
    externalId
    currentState
    userType
    office
    email
    connectPassword
    firstName
    lastName
    address
    dateOfBirth
    preferredName
    threshold {
      id
      owner
      account
      careProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      name
      minimum
      maximum
      userThresholdId
      message
    }
    account
    deviceTypes
    userCareProfile {
      id
      account
      owner
      user {
        id
        owner
        messages {
          items {
            id
            owner
            transcript
            priority
            userID
            xmit
            messageUserId
            createdAt
          }
          nextToken
        }
        groups
        firstRun
        isDeveloper
        currentScene
        orgName
        orbId
        externalId
        currentState
        userType
        office
        email
        connectPassword
        firstName
        lastName
        address
        dateOfBirth
        preferredName
        threshold {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        account
        deviceTypes
        userCareProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        devices {
          items {
            id
            owner
            deviceId
            deviceType
            rapidPhoneNumber
          }
          nextToken
        }
        rapidEvents {
          items {
            id
            owner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        aneltoEvents {
          items {
            id
            userEventsId
            Account
            BPM
            BPMHigh
            BPMLow
            Battery
            BloodPressure
            BlueId
            DeviceNumber
            Diastolic
            Event
            EventCode
            Glucose
            HeartRate
            Id
            PulseAvg
            PulseHigh
            PulseLow
            PulseOx
            RawData
            Systolic
            Thermometer
            TimeStamp
            Timestamp
            Units
            User
            UserId
            Value
            Vital
            Weight
            owner
            createdAt
            type
          }
          nextToken
        }
        responsibleParties {
          items {
            id
            owner
            phone
          }
          nextToken
        }
        reminderSchedules {
          items {
            id
            owner
            timestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        lexInteractions {
          items {
            id
            owner
            inputTranscript
            timestamp
            botName
            botAlias
            prompt
            intentName
            slots
            sessionAttributes
            requestAttributes
          }
          nextToken
        }
        iotEvent {
          items {
            frame
            timestamp
            sysTimeMS
            camSerialNum
            awsUser
            messageType
            owner
            username
            createdAt
          }
          nextToken
        }
      }
      threshold {
        items {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        nextToken
      }
      medicationComplianceStreak
      numMedications
      vitalTypes
      profileDetails {
        ethnicity
        height
        weight
        education
        income
        emergencyContactConfirm
        caregiver
        fallLast3Years
        lastFallDate
        tobacco
        tobaccoUse
        tobaccoQuit
        substancesYN
        substances
        substancesFrequency
        substancesQuit
        hearing
        hearingPartial
        hearingAid
        vision
        visionPartial
        visionAid
        visionColor
        rehabilitationStartDate
        primaryCare
        secondaryCare {
          name
          phoneNumber
          specialistYN
          specialty
        }
        preExistingConditions
        behaviorHealth
        lastHospitalVisit
        overCounter
        pharmacyName
        pharmacyConcerns
        supplementalOxygen
        surgicalHistory
        rehabilitation
        dietRestriction
        fluidRestriction
        sleepingDifficulties
        sleepingAids
        restroomNight
        restroomFrequency
        bowelComplaints
        urinaryComplaints
        additionalPeople
        safeAtHome
        wellbeing
        pets
        cats
        catsCount
        dogs
        dogsCount
        additionalPets
        specialConcerns
        bloodType
      }
    }
    devices {
      items {
        id
        owner
        deviceId
        deviceType
        rapidPhoneNumber
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    rapidEvents {
      items {
        id
        owner
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        Longitude
        Latitude
      }
      nextToken
    }
    aneltoEvents {
      items {
        id
        userEventsId
        Account
        BPM
        BPMHigh
        BPMLow
        Battery
        BloodPressure
        BlueId
        DeviceNumber
        Diastolic
        Event
        EventCode
        Glucose
        HeartRate
        Id
        PulseAvg
        PulseHigh
        PulseLow
        PulseOx
        RawData
        Systolic
        Thermometer
        TimeStamp
        Timestamp
        Units
        User
        UserId
        Value
        Vital
        Weight
        owner
        createdAt
        type
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    responsibleParties {
      items {
        id
        owner
        phone
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    reminderSchedules {
      items {
        id
        owner
        medicationDetails {
          id
          transcript
          frequency
          lastTaken
          dosageAmount
          dosageUnit
          time
          route
          name
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          day
          createdAt
          updatedAt
        }
        activityDetails {
          id
          transcript
          frequency
          time
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          day
          createdAt
          updatedAt
        }
        vitalDetails {
          id
          transcript
          frequency
          vitalType
          time
          day
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    lexInteractions {
      items {
        id
        owner
        inputTranscript
        timestamp
        botName
        botAlias
        prompt
        intentName
        slots
        sessionAttributes
        requestAttributes
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    iotEvent {
      items {
        frame
        timestamp
        sysTimeMS
        camSerialNum
        awsUser
        messageType
        owner
        username
        createdAt
        content {
          cadence {
            samples
            min
            mean
            max
          }
          speed {
            samples
            min
            mean
            max
          }
          stanceTime {
            samples
            min
            mean
            max
          }
          step {
            samples
            min
            mean
            max
          }
          stepTime {
            samples
            min
            mean
            max
          }
          stepWidth {
            samples
            min
            mean
            max
          }
          stride {
            samples
            min
            mean
            max
          }
          strideTime {
            samples
            min
            mean
            max
          }
          strideVelocity {
            samples
            min
            mean
            max
          }
          swingTime {
            samples
            min
            mean
            max
          }
          event
        }
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser($owner: String) {
  onUpdateUser(owner: $owner) {
    id
    owner
    messages {
      items {
        id
        owner
        transcript
        priority
        userID
        xmit
        messageUserId
        createdAt
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    groups
    firstRun
    isDeveloper
    currentScene
    orgName
    orbId
    externalId
    currentState
    userType
    office
    email
    connectPassword
    firstName
    lastName
    address
    dateOfBirth
    preferredName
    threshold {
      id
      owner
      account
      careProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      name
      minimum
      maximum
      userThresholdId
      message
    }
    account
    deviceTypes
    userCareProfile {
      id
      account
      owner
      user {
        id
        owner
        messages {
          items {
            id
            owner
            transcript
            priority
            userID
            xmit
            messageUserId
            createdAt
          }
          nextToken
        }
        groups
        firstRun
        isDeveloper
        currentScene
        orgName
        orbId
        externalId
        currentState
        userType
        office
        email
        connectPassword
        firstName
        lastName
        address
        dateOfBirth
        preferredName
        threshold {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        account
        deviceTypes
        userCareProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        devices {
          items {
            id
            owner
            deviceId
            deviceType
            rapidPhoneNumber
          }
          nextToken
        }
        rapidEvents {
          items {
            id
            owner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        aneltoEvents {
          items {
            id
            userEventsId
            Account
            BPM
            BPMHigh
            BPMLow
            Battery
            BloodPressure
            BlueId
            DeviceNumber
            Diastolic
            Event
            EventCode
            Glucose
            HeartRate
            Id
            PulseAvg
            PulseHigh
            PulseLow
            PulseOx
            RawData
            Systolic
            Thermometer
            TimeStamp
            Timestamp
            Units
            User
            UserId
            Value
            Vital
            Weight
            owner
            createdAt
            type
          }
          nextToken
        }
        responsibleParties {
          items {
            id
            owner
            phone
          }
          nextToken
        }
        reminderSchedules {
          items {
            id
            owner
            timestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        lexInteractions {
          items {
            id
            owner
            inputTranscript
            timestamp
            botName
            botAlias
            prompt
            intentName
            slots
            sessionAttributes
            requestAttributes
          }
          nextToken
        }
        iotEvent {
          items {
            frame
            timestamp
            sysTimeMS
            camSerialNum
            awsUser
            messageType
            owner
            username
            createdAt
          }
          nextToken
        }
      }
      threshold {
        items {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        nextToken
      }
      medicationComplianceStreak
      numMedications
      vitalTypes
      profileDetails {
        ethnicity
        height
        weight
        education
        income
        emergencyContactConfirm
        caregiver
        fallLast3Years
        lastFallDate
        tobacco
        tobaccoUse
        tobaccoQuit
        substancesYN
        substances
        substancesFrequency
        substancesQuit
        hearing
        hearingPartial
        hearingAid
        vision
        visionPartial
        visionAid
        visionColor
        rehabilitationStartDate
        primaryCare
        secondaryCare {
          name
          phoneNumber
          specialistYN
          specialty
        }
        preExistingConditions
        behaviorHealth
        lastHospitalVisit
        overCounter
        pharmacyName
        pharmacyConcerns
        supplementalOxygen
        surgicalHistory
        rehabilitation
        dietRestriction
        fluidRestriction
        sleepingDifficulties
        sleepingAids
        restroomNight
        restroomFrequency
        bowelComplaints
        urinaryComplaints
        additionalPeople
        safeAtHome
        wellbeing
        pets
        cats
        catsCount
        dogs
        dogsCount
        additionalPets
        specialConcerns
        bloodType
      }
    }
    devices {
      items {
        id
        owner
        deviceId
        deviceType
        rapidPhoneNumber
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    rapidEvents {
      items {
        id
        owner
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        Longitude
        Latitude
      }
      nextToken
    }
    aneltoEvents {
      items {
        id
        userEventsId
        Account
        BPM
        BPMHigh
        BPMLow
        Battery
        BloodPressure
        BlueId
        DeviceNumber
        Diastolic
        Event
        EventCode
        Glucose
        HeartRate
        Id
        PulseAvg
        PulseHigh
        PulseLow
        PulseOx
        RawData
        Systolic
        Thermometer
        TimeStamp
        Timestamp
        Units
        User
        UserId
        Value
        Vital
        Weight
        owner
        createdAt
        type
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    responsibleParties {
      items {
        id
        owner
        phone
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    reminderSchedules {
      items {
        id
        owner
        medicationDetails {
          id
          transcript
          frequency
          lastTaken
          dosageAmount
          dosageUnit
          time
          route
          name
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          day
          createdAt
          updatedAt
        }
        activityDetails {
          id
          transcript
          frequency
          time
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          day
          createdAt
          updatedAt
        }
        vitalDetails {
          id
          transcript
          frequency
          vitalType
          time
          day
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    lexInteractions {
      items {
        id
        owner
        inputTranscript
        timestamp
        botName
        botAlias
        prompt
        intentName
        slots
        sessionAttributes
        requestAttributes
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    iotEvent {
      items {
        frame
        timestamp
        sysTimeMS
        camSerialNum
        awsUser
        messageType
        owner
        username
        createdAt
        content {
          cadence {
            samples
            min
            mean
            max
          }
          speed {
            samples
            min
            mean
            max
          }
          stanceTime {
            samples
            min
            mean
            max
          }
          step {
            samples
            min
            mean
            max
          }
          stepTime {
            samples
            min
            mean
            max
          }
          stepWidth {
            samples
            min
            mean
            max
          }
          stride {
            samples
            min
            mean
            max
          }
          strideTime {
            samples
            min
            mean
            max
          }
          strideVelocity {
            samples
            min
            mean
            max
          }
          swingTime {
            samples
            min
            mean
            max
          }
          event
        }
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser($owner: String) {
  onDeleteUser(owner: $owner) {
    id
    owner
    messages {
      items {
        id
        owner
        transcript
        priority
        userID
        xmit
        messageUserId
        createdAt
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    groups
    firstRun
    isDeveloper
    currentScene
    orgName
    orbId
    externalId
    currentState
    userType
    office
    email
    connectPassword
    firstName
    lastName
    address
    dateOfBirth
    preferredName
    threshold {
      id
      owner
      account
      careProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      name
      minimum
      maximum
      userThresholdId
      message
    }
    account
    deviceTypes
    userCareProfile {
      id
      account
      owner
      user {
        id
        owner
        messages {
          items {
            id
            owner
            transcript
            priority
            userID
            xmit
            messageUserId
            createdAt
          }
          nextToken
        }
        groups
        firstRun
        isDeveloper
        currentScene
        orgName
        orbId
        externalId
        currentState
        userType
        office
        email
        connectPassword
        firstName
        lastName
        address
        dateOfBirth
        preferredName
        threshold {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        account
        deviceTypes
        userCareProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        devices {
          items {
            id
            owner
            deviceId
            deviceType
            rapidPhoneNumber
          }
          nextToken
        }
        rapidEvents {
          items {
            id
            owner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        aneltoEvents {
          items {
            id
            userEventsId
            Account
            BPM
            BPMHigh
            BPMLow
            Battery
            BloodPressure
            BlueId
            DeviceNumber
            Diastolic
            Event
            EventCode
            Glucose
            HeartRate
            Id
            PulseAvg
            PulseHigh
            PulseLow
            PulseOx
            RawData
            Systolic
            Thermometer
            TimeStamp
            Timestamp
            Units
            User
            UserId
            Value
            Vital
            Weight
            owner
            createdAt
            type
          }
          nextToken
        }
        responsibleParties {
          items {
            id
            owner
            phone
          }
          nextToken
        }
        reminderSchedules {
          items {
            id
            owner
            timestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        lexInteractions {
          items {
            id
            owner
            inputTranscript
            timestamp
            botName
            botAlias
            prompt
            intentName
            slots
            sessionAttributes
            requestAttributes
          }
          nextToken
        }
        iotEvent {
          items {
            frame
            timestamp
            sysTimeMS
            camSerialNum
            awsUser
            messageType
            owner
            username
            createdAt
          }
          nextToken
        }
      }
      threshold {
        items {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        nextToken
      }
      medicationComplianceStreak
      numMedications
      vitalTypes
      profileDetails {
        ethnicity
        height
        weight
        education
        income
        emergencyContactConfirm
        caregiver
        fallLast3Years
        lastFallDate
        tobacco
        tobaccoUse
        tobaccoQuit
        substancesYN
        substances
        substancesFrequency
        substancesQuit
        hearing
        hearingPartial
        hearingAid
        vision
        visionPartial
        visionAid
        visionColor
        rehabilitationStartDate
        primaryCare
        secondaryCare {
          name
          phoneNumber
          specialistYN
          specialty
        }
        preExistingConditions
        behaviorHealth
        lastHospitalVisit
        overCounter
        pharmacyName
        pharmacyConcerns
        supplementalOxygen
        surgicalHistory
        rehabilitation
        dietRestriction
        fluidRestriction
        sleepingDifficulties
        sleepingAids
        restroomNight
        restroomFrequency
        bowelComplaints
        urinaryComplaints
        additionalPeople
        safeAtHome
        wellbeing
        pets
        cats
        catsCount
        dogs
        dogsCount
        additionalPets
        specialConcerns
        bloodType
      }
    }
    devices {
      items {
        id
        owner
        deviceId
        deviceType
        rapidPhoneNumber
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    rapidEvents {
      items {
        id
        owner
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        Longitude
        Latitude
      }
      nextToken
    }
    aneltoEvents {
      items {
        id
        userEventsId
        Account
        BPM
        BPMHigh
        BPMLow
        Battery
        BloodPressure
        BlueId
        DeviceNumber
        Diastolic
        Event
        EventCode
        Glucose
        HeartRate
        Id
        PulseAvg
        PulseHigh
        PulseLow
        PulseOx
        RawData
        Systolic
        Thermometer
        TimeStamp
        Timestamp
        Units
        User
        UserId
        Value
        Vital
        Weight
        owner
        createdAt
        type
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    responsibleParties {
      items {
        id
        owner
        phone
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    reminderSchedules {
      items {
        id
        owner
        medicationDetails {
          id
          transcript
          frequency
          lastTaken
          dosageAmount
          dosageUnit
          time
          route
          name
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          day
          createdAt
          updatedAt
        }
        activityDetails {
          id
          transcript
          frequency
          time
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          day
          createdAt
          updatedAt
        }
        vitalDetails {
          id
          transcript
          frequency
          vitalType
          time
          day
          note {
            id
            author
            details
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    lexInteractions {
      items {
        id
        owner
        inputTranscript
        timestamp
        botName
        botAlias
        prompt
        intentName
        slots
        sessionAttributes
        requestAttributes
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
    iotEvent {
      items {
        frame
        timestamp
        sysTimeMS
        camSerialNum
        awsUser
        messageType
        owner
        username
        createdAt
        content {
          cadence {
            samples
            min
            mean
            max
          }
          speed {
            samples
            min
            mean
            max
          }
          stanceTime {
            samples
            min
            mean
            max
          }
          step {
            samples
            min
            mean
            max
          }
          stepTime {
            samples
            min
            mean
            max
          }
          stepWidth {
            samples
            min
            mean
            max
          }
          stride {
            samples
            min
            mean
            max
          }
          strideTime {
            samples
            min
            mean
            max
          }
          strideVelocity {
            samples
            min
            mean
            max
          }
          swingTime {
            samples
            min
            mean
            max
          }
          event
        }
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const onCreateMessage = `subscription OnCreateMessage($owner: String) {
  onCreateMessage(owner: $owner) {
    id
    owner
    transcript
    priority
    userID
    xmit
    messageUserId
    createdAt
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateMessage = `subscription OnUpdateMessage($owner: String) {
  onUpdateMessage(owner: $owner) {
    id
    owner
    transcript
    priority
    userID
    xmit
    messageUserId
    createdAt
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteMessage = `subscription OnDeleteMessage($owner: String) {
  onDeleteMessage(owner: $owner) {
    id
    owner
    transcript
    priority
    userID
    xmit
    messageUserId
    createdAt
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateThreshold = `subscription OnCreateThreshold($owner: String) {
  onCreateThreshold(owner: $owner) {
    id
    owner
    account
    careProfile {
      id
      account
      owner
      user {
        id
        owner
        messages {
          items {
            id
            owner
            transcript
            priority
            userID
            xmit
            messageUserId
            createdAt
          }
          nextToken
        }
        groups
        firstRun
        isDeveloper
        currentScene
        orgName
        orbId
        externalId
        currentState
        userType
        office
        email
        connectPassword
        firstName
        lastName
        address
        dateOfBirth
        preferredName
        threshold {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        account
        deviceTypes
        userCareProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        devices {
          items {
            id
            owner
            deviceId
            deviceType
            rapidPhoneNumber
          }
          nextToken
        }
        rapidEvents {
          items {
            id
            owner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        aneltoEvents {
          items {
            id
            userEventsId
            Account
            BPM
            BPMHigh
            BPMLow
            Battery
            BloodPressure
            BlueId
            DeviceNumber
            Diastolic
            Event
            EventCode
            Glucose
            HeartRate
            Id
            PulseAvg
            PulseHigh
            PulseLow
            PulseOx
            RawData
            Systolic
            Thermometer
            TimeStamp
            Timestamp
            Units
            User
            UserId
            Value
            Vital
            Weight
            owner
            createdAt
            type
          }
          nextToken
        }
        responsibleParties {
          items {
            id
            owner
            phone
          }
          nextToken
        }
        reminderSchedules {
          items {
            id
            owner
            timestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        lexInteractions {
          items {
            id
            owner
            inputTranscript
            timestamp
            botName
            botAlias
            prompt
            intentName
            slots
            sessionAttributes
            requestAttributes
          }
          nextToken
        }
        iotEvent {
          items {
            frame
            timestamp
            sysTimeMS
            camSerialNum
            awsUser
            messageType
            owner
            username
            createdAt
          }
          nextToken
        }
      }
      threshold {
        items {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        nextToken
      }
      medicationComplianceStreak
      numMedications
      vitalTypes
      profileDetails {
        ethnicity
        height
        weight
        education
        income
        emergencyContactConfirm
        caregiver
        fallLast3Years
        lastFallDate
        tobacco
        tobaccoUse
        tobaccoQuit
        substancesYN
        substances
        substancesFrequency
        substancesQuit
        hearing
        hearingPartial
        hearingAid
        vision
        visionPartial
        visionAid
        visionColor
        rehabilitationStartDate
        primaryCare
        secondaryCare {
          name
          phoneNumber
          specialistYN
          specialty
        }
        preExistingConditions
        behaviorHealth
        lastHospitalVisit
        overCounter
        pharmacyName
        pharmacyConcerns
        supplementalOxygen
        surgicalHistory
        rehabilitation
        dietRestriction
        fluidRestriction
        sleepingDifficulties
        sleepingAids
        restroomNight
        restroomFrequency
        bowelComplaints
        urinaryComplaints
        additionalPeople
        safeAtHome
        wellbeing
        pets
        cats
        catsCount
        dogs
        dogsCount
        additionalPets
        specialConcerns
        bloodType
      }
    }
    name
    minimum
    maximum
    userThresholdId
    message
  }
}
`;
export const onUpdateThreshold = `subscription OnUpdateThreshold($owner: String) {
  onUpdateThreshold(owner: $owner) {
    id
    owner
    account
    careProfile {
      id
      account
      owner
      user {
        id
        owner
        messages {
          items {
            id
            owner
            transcript
            priority
            userID
            xmit
            messageUserId
            createdAt
          }
          nextToken
        }
        groups
        firstRun
        isDeveloper
        currentScene
        orgName
        orbId
        externalId
        currentState
        userType
        office
        email
        connectPassword
        firstName
        lastName
        address
        dateOfBirth
        preferredName
        threshold {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        account
        deviceTypes
        userCareProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        devices {
          items {
            id
            owner
            deviceId
            deviceType
            rapidPhoneNumber
          }
          nextToken
        }
        rapidEvents {
          items {
            id
            owner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        aneltoEvents {
          items {
            id
            userEventsId
            Account
            BPM
            BPMHigh
            BPMLow
            Battery
            BloodPressure
            BlueId
            DeviceNumber
            Diastolic
            Event
            EventCode
            Glucose
            HeartRate
            Id
            PulseAvg
            PulseHigh
            PulseLow
            PulseOx
            RawData
            Systolic
            Thermometer
            TimeStamp
            Timestamp
            Units
            User
            UserId
            Value
            Vital
            Weight
            owner
            createdAt
            type
          }
          nextToken
        }
        responsibleParties {
          items {
            id
            owner
            phone
          }
          nextToken
        }
        reminderSchedules {
          items {
            id
            owner
            timestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        lexInteractions {
          items {
            id
            owner
            inputTranscript
            timestamp
            botName
            botAlias
            prompt
            intentName
            slots
            sessionAttributes
            requestAttributes
          }
          nextToken
        }
        iotEvent {
          items {
            frame
            timestamp
            sysTimeMS
            camSerialNum
            awsUser
            messageType
            owner
            username
            createdAt
          }
          nextToken
        }
      }
      threshold {
        items {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        nextToken
      }
      medicationComplianceStreak
      numMedications
      vitalTypes
      profileDetails {
        ethnicity
        height
        weight
        education
        income
        emergencyContactConfirm
        caregiver
        fallLast3Years
        lastFallDate
        tobacco
        tobaccoUse
        tobaccoQuit
        substancesYN
        substances
        substancesFrequency
        substancesQuit
        hearing
        hearingPartial
        hearingAid
        vision
        visionPartial
        visionAid
        visionColor
        rehabilitationStartDate
        primaryCare
        secondaryCare {
          name
          phoneNumber
          specialistYN
          specialty
        }
        preExistingConditions
        behaviorHealth
        lastHospitalVisit
        overCounter
        pharmacyName
        pharmacyConcerns
        supplementalOxygen
        surgicalHistory
        rehabilitation
        dietRestriction
        fluidRestriction
        sleepingDifficulties
        sleepingAids
        restroomNight
        restroomFrequency
        bowelComplaints
        urinaryComplaints
        additionalPeople
        safeAtHome
        wellbeing
        pets
        cats
        catsCount
        dogs
        dogsCount
        additionalPets
        specialConcerns
        bloodType
      }
    }
    name
    minimum
    maximum
    userThresholdId
    message
  }
}
`;
export const onDeleteThreshold = `subscription OnDeleteThreshold($owner: String) {
  onDeleteThreshold(owner: $owner) {
    id
    owner
    account
    careProfile {
      id
      account
      owner
      user {
        id
        owner
        messages {
          items {
            id
            owner
            transcript
            priority
            userID
            xmit
            messageUserId
            createdAt
          }
          nextToken
        }
        groups
        firstRun
        isDeveloper
        currentScene
        orgName
        orbId
        externalId
        currentState
        userType
        office
        email
        connectPassword
        firstName
        lastName
        address
        dateOfBirth
        preferredName
        threshold {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        account
        deviceTypes
        userCareProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        devices {
          items {
            id
            owner
            deviceId
            deviceType
            rapidPhoneNumber
          }
          nextToken
        }
        rapidEvents {
          items {
            id
            owner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        aneltoEvents {
          items {
            id
            userEventsId
            Account
            BPM
            BPMHigh
            BPMLow
            Battery
            BloodPressure
            BlueId
            DeviceNumber
            Diastolic
            Event
            EventCode
            Glucose
            HeartRate
            Id
            PulseAvg
            PulseHigh
            PulseLow
            PulseOx
            RawData
            Systolic
            Thermometer
            TimeStamp
            Timestamp
            Units
            User
            UserId
            Value
            Vital
            Weight
            owner
            createdAt
            type
          }
          nextToken
        }
        responsibleParties {
          items {
            id
            owner
            phone
          }
          nextToken
        }
        reminderSchedules {
          items {
            id
            owner
            timestamp
            createdAt
            updatedAt
          }
          nextToken
        }
        lexInteractions {
          items {
            id
            owner
            inputTranscript
            timestamp
            botName
            botAlias
            prompt
            intentName
            slots
            sessionAttributes
            requestAttributes
          }
          nextToken
        }
        iotEvent {
          items {
            frame
            timestamp
            sysTimeMS
            camSerialNum
            awsUser
            messageType
            owner
            username
            createdAt
          }
          nextToken
        }
      }
      threshold {
        items {
          id
          owner
          account
          careProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          name
          minimum
          maximum
          userThresholdId
          message
        }
        nextToken
      }
      medicationComplianceStreak
      numMedications
      vitalTypes
      profileDetails {
        ethnicity
        height
        weight
        education
        income
        emergencyContactConfirm
        caregiver
        fallLast3Years
        lastFallDate
        tobacco
        tobaccoUse
        tobaccoQuit
        substancesYN
        substances
        substancesFrequency
        substancesQuit
        hearing
        hearingPartial
        hearingAid
        vision
        visionPartial
        visionAid
        visionColor
        rehabilitationStartDate
        primaryCare
        secondaryCare {
          name
          phoneNumber
          specialistYN
          specialty
        }
        preExistingConditions
        behaviorHealth
        lastHospitalVisit
        overCounter
        pharmacyName
        pharmacyConcerns
        supplementalOxygen
        surgicalHistory
        rehabilitation
        dietRestriction
        fluidRestriction
        sleepingDifficulties
        sleepingAids
        restroomNight
        restroomFrequency
        bowelComplaints
        urinaryComplaints
        additionalPeople
        safeAtHome
        wellbeing
        pets
        cats
        catsCount
        dogs
        dogsCount
        additionalPets
        specialConcerns
        bloodType
      }
    }
    name
    minimum
    maximum
    userThresholdId
    message
  }
}
`;
export const onCreateSceneTrigger = `subscription OnCreateSceneTrigger($owner: String) {
  onCreateSceneTrigger(owner: $owner) {
    id
    owner
    sceneConfig
  }
}
`;
export const onUpdateSceneTrigger = `subscription OnUpdateSceneTrigger($owner: String) {
  onUpdateSceneTrigger(owner: $owner) {
    id
    owner
    sceneConfig
  }
}
`;
export const onDeleteSceneTrigger = `subscription OnDeleteSceneTrigger($owner: String) {
  onDeleteSceneTrigger(owner: $owner) {
    id
    owner
    sceneConfig
  }
}
`;
export const onCreateScheduledEvent = `subscription OnCreateScheduledEvent($owner: String) {
  onCreateScheduledEvent(owner: $owner) {
    id
    owner
    type
    acknowledged
    data
  }
}
`;
export const onUpdateScheduledEvent = `subscription OnUpdateScheduledEvent($owner: String) {
  onUpdateScheduledEvent(owner: $owner) {
    id
    owner
    type
    acknowledged
    data
  }
}
`;
export const onDeleteScheduledEvent = `subscription OnDeleteScheduledEvent($owner: String) {
  onDeleteScheduledEvent(owner: $owner) {
    id
    owner
    type
    acknowledged
    data
  }
}
`;
export const onCreateLexInteraction = `subscription OnCreateLexInteraction($owner: String) {
  onCreateLexInteraction(owner: $owner) {
    id
    owner
    inputTranscript
    timestamp
    botName
    botAlias
    prompt
    intentName
    slots
    sessionAttributes
    requestAttributes
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateLexInteraction = `subscription OnUpdateLexInteraction($owner: String) {
  onUpdateLexInteraction(owner: $owner) {
    id
    owner
    inputTranscript
    timestamp
    botName
    botAlias
    prompt
    intentName
    slots
    sessionAttributes
    requestAttributes
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteLexInteraction = `subscription OnDeleteLexInteraction($owner: String) {
  onDeleteLexInteraction(owner: $owner) {
    id
    owner
    inputTranscript
    timestamp
    botName
    botAlias
    prompt
    intentName
    slots
    sessionAttributes
    requestAttributes
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateResponsibleParty = `subscription OnCreateResponsibleParty($owner: String) {
  onCreateResponsibleParty(owner: $owner) {
    id
    owner
    phone
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateResponsibleParty = `subscription OnUpdateResponsibleParty($owner: String) {
  onUpdateResponsibleParty(owner: $owner) {
    id
    owner
    phone
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteResponsibleParty = `subscription OnDeleteResponsibleParty($owner: String) {
  onDeleteResponsibleParty(owner: $owner) {
    id
    owner
    phone
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateIoTEvent = `subscription OnCreateIoTEvent($owner: String) {
  onCreateIoTEvent(owner: $owner) {
    frame
    timestamp
    sysTimeMS
    camSerialNum
    awsUser
    messageType
    owner
    username
    createdAt
    content {
      cadence {
        samples
        min
        mean
        max
      }
      speed {
        samples
        min
        mean
        max
      }
      stanceTime {
        samples
        min
        mean
        max
      }
      step {
        samples
        min
        mean
        max
      }
      stepTime {
        samples
        min
        mean
        max
      }
      stepWidth {
        samples
        min
        mean
        max
      }
      stride {
        samples
        min
        mean
        max
      }
      strideTime {
        samples
        min
        mean
        max
      }
      strideVelocity {
        samples
        min
        mean
        max
      }
      swingTime {
        samples
        min
        mean
        max
      }
      event
    }
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateIoTEvent = `subscription OnUpdateIoTEvent($owner: String) {
  onUpdateIoTEvent(owner: $owner) {
    frame
    timestamp
    sysTimeMS
    camSerialNum
    awsUser
    messageType
    owner
    username
    createdAt
    content {
      cadence {
        samples
        min
        mean
        max
      }
      speed {
        samples
        min
        mean
        max
      }
      stanceTime {
        samples
        min
        mean
        max
      }
      step {
        samples
        min
        mean
        max
      }
      stepTime {
        samples
        min
        mean
        max
      }
      stepWidth {
        samples
        min
        mean
        max
      }
      stride {
        samples
        min
        mean
        max
      }
      strideTime {
        samples
        min
        mean
        max
      }
      strideVelocity {
        samples
        min
        mean
        max
      }
      swingTime {
        samples
        min
        mean
        max
      }
      event
    }
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteIoTEvent = `subscription OnDeleteIoTEvent($owner: String) {
  onDeleteIoTEvent(owner: $owner) {
    frame
    timestamp
    sysTimeMS
    camSerialNum
    awsUser
    messageType
    owner
    username
    createdAt
    content {
      cadence {
        samples
        min
        mean
        max
      }
      speed {
        samples
        min
        mean
        max
      }
      stanceTime {
        samples
        min
        mean
        max
      }
      step {
        samples
        min
        mean
        max
      }
      stepTime {
        samples
        min
        mean
        max
      }
      stepWidth {
        samples
        min
        mean
        max
      }
      stride {
        samples
        min
        mean
        max
      }
      strideTime {
        samples
        min
        mean
        max
      }
      strideVelocity {
        samples
        min
        mean
        max
      }
      swingTime {
        samples
        min
        mean
        max
      }
      event
    }
    user {
      id
      owner
      messages {
        items {
          id
          owner
          transcript
          priority
          userID
          xmit
          messageUserId
          createdAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      groups
      firstRun
      isDeveloper
      currentScene
      orgName
      orbId
      externalId
      currentState
      userType
      office
      email
      connectPassword
      firstName
      lastName
      address
      dateOfBirth
      preferredName
      threshold {
        id
        owner
        account
        careProfile {
          id
          account
          owner
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          threshold {
            nextToken
          }
          medicationComplianceStreak
          numMedications
          vitalTypes
          profileDetails {
            ethnicity
            height
            weight
            education
            income
            emergencyContactConfirm
            caregiver
            fallLast3Years
            lastFallDate
            tobacco
            tobaccoUse
            tobaccoQuit
            substancesYN
            substances
            substancesFrequency
            substancesQuit
            hearing
            hearingPartial
            hearingAid
            vision
            visionPartial
            visionAid
            visionColor
            rehabilitationStartDate
            primaryCare
            preExistingConditions
            behaviorHealth
            lastHospitalVisit
            overCounter
            pharmacyName
            pharmacyConcerns
            supplementalOxygen
            surgicalHistory
            rehabilitation
            dietRestriction
            fluidRestriction
            sleepingDifficulties
            sleepingAids
            restroomNight
            restroomFrequency
            bowelComplaints
            urinaryComplaints
            additionalPeople
            safeAtHome
            wellbeing
            pets
            cats
            catsCount
            dogs
            dogsCount
            additionalPets
            specialConcerns
            bloodType
          }
        }
        name
        minimum
        maximum
        userThresholdId
        message
      }
      account
      deviceTypes
      userCareProfile {
        id
        account
        owner
        user {
          id
          owner
          messages {
            nextToken
          }
          groups
          firstRun
          isDeveloper
          currentScene
          orgName
          orbId
          externalId
          currentState
          userType
          office
          email
          connectPassword
          firstName
          lastName
          address
          dateOfBirth
          preferredName
          threshold {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          account
          deviceTypes
          userCareProfile {
            id
            account
            owner
            medicationComplianceStreak
            numMedications
            vitalTypes
          }
          devices {
            nextToken
          }
          rapidEvents {
            nextToken
          }
          aneltoEvents {
            nextToken
          }
          responsibleParties {
            nextToken
          }
          reminderSchedules {
            nextToken
          }
          lexInteractions {
            nextToken
          }
          iotEvent {
            nextToken
          }
        }
        threshold {
          items {
            id
            owner
            account
            name
            minimum
            maximum
            userThresholdId
            message
          }
          nextToken
        }
        medicationComplianceStreak
        numMedications
        vitalTypes
        profileDetails {
          ethnicity
          height
          weight
          education
          income
          emergencyContactConfirm
          caregiver
          fallLast3Years
          lastFallDate
          tobacco
          tobaccoUse
          tobaccoQuit
          substancesYN
          substances
          substancesFrequency
          substancesQuit
          hearing
          hearingPartial
          hearingAid
          vision
          visionPartial
          visionAid
          visionColor
          rehabilitationStartDate
          primaryCare
          secondaryCare {
            name
            phoneNumber
            specialistYN
            specialty
          }
          preExistingConditions
          behaviorHealth
          lastHospitalVisit
          overCounter
          pharmacyName
          pharmacyConcerns
          supplementalOxygen
          surgicalHistory
          rehabilitation
          dietRestriction
          fluidRestriction
          sleepingDifficulties
          sleepingAids
          restroomNight
          restroomFrequency
          bowelComplaints
          urinaryComplaints
          additionalPeople
          safeAtHome
          wellbeing
          pets
          cats
          catsCount
          dogs
          dogsCount
          additionalPets
          specialConcerns
          bloodType
        }
      }
      devices {
        items {
          id
          owner
          deviceId
          deviceType
          rapidPhoneNumber
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      rapidEvents {
        items {
          id
          owner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
          Longitude
          Latitude
        }
        nextToken
      }
      aneltoEvents {
        items {
          id
          userEventsId
          Account
          BPM
          BPMHigh
          BPMLow
          Battery
          BloodPressure
          BlueId
          DeviceNumber
          Diastolic
          Event
          EventCode
          Glucose
          HeartRate
          Id
          PulseAvg
          PulseHigh
          PulseLow
          PulseOx
          RawData
          Systolic
          Thermometer
          TimeStamp
          Timestamp
          Units
          User
          UserId
          Value
          Vital
          Weight
          owner
          createdAt
          type
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      responsibleParties {
        items {
          id
          owner
          phone
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      reminderSchedules {
        items {
          id
          owner
          medicationDetails {
            id
            transcript
            frequency
            lastTaken
            dosageAmount
            dosageUnit
            time
            route
            name
            day
            createdAt
            updatedAt
          }
          activityDetails {
            id
            transcript
            frequency
            time
            day
            createdAt
            updatedAt
          }
          vitalDetails {
            id
            transcript
            frequency
            vitalType
            time
            day
            createdAt
            updatedAt
          }
          timestamp
          createdAt
          updatedAt
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      lexInteractions {
        items {
          id
          owner
          inputTranscript
          timestamp
          botName
          botAlias
          prompt
          intentName
          slots
          sessionAttributes
          requestAttributes
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
      iotEvent {
        items {
          frame
          timestamp
          sysTimeMS
          camSerialNum
          awsUser
          messageType
          owner
          username
          createdAt
          content {
            event
          }
          user {
            id
            owner
            groups
            firstRun
            isDeveloper
            currentScene
            orgName
            orbId
            externalId
            currentState
            userType
            office
            email
            connectPassword
            firstName
            lastName
            address
            dateOfBirth
            preferredName
            account
            deviceTypes
          }
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateMotionAnalysisGroundTruth = `subscription OnCreateMotionAnalysisGroundTruth($owner: String) {
  onCreateMotionAnalysisGroundTruth(owner: $owner) {
    groundTruthId
    cadence
    strideSpeed
    gaitSpeed
    stepLength
    createdAt
    owner
  }
}
`;
export const onUpdateMotionAnalysisGroundTruth = `subscription OnUpdateMotionAnalysisGroundTruth($owner: String) {
  onUpdateMotionAnalysisGroundTruth(owner: $owner) {
    groundTruthId
    cadence
    strideSpeed
    gaitSpeed
    stepLength
    createdAt
    owner
  }
}
`;
export const onDeleteMotionAnalysisGroundTruth = `subscription OnDeleteMotionAnalysisGroundTruth($owner: String) {
  onDeleteMotionAnalysisGroundTruth(owner: $owner) {
    groundTruthId
    cadence
    strideSpeed
    gaitSpeed
    stepLength
    createdAt
    owner
  }
}
`;

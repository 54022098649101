/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/**
 * Handle Click
 *
 * @author [Martin Jencka]
 */

import { XR, Hub, Logger } from 'aws-amplify';
import { debounce } from 'lodash';

import store from '../../_GlobalStateStore/GlobalStateStore';
// eslint-disable-next-line import/extensions
import SFX from './SFX.js';

let audioEnabled = false;

const logger = new Logger('ClickHandler');

export const handleClick = (element) => {
  if (!audioEnabled) {
    XR.enableAudio('introScene');
    audioEnabled = true;
  }
  logger.debug('handleClick():', element);

  /*
    ___ _   _ __  __ ___ ___ ___   _   _  _   ___ __  __ ___ _____ ___
   / __| | | |  \/  | __| _ \_ _| /_\ | \| | | __|  \/  |_ _|_   _/ __|
   \__ \ |_| | |\/| | _||   /| | / _ \| .` | | _|| |\/| || |  | | \__ \
   |___/\___/|_|  |_|___|_|_\___/_/ \_\_|\_| |___|_|  |_|___| |_| |___/

                       -- Sumerian Emits --
                             (sEmits)

      This handles all generic emits through Sumerian's Systembus

  */

  if (element != null) {
    // Sound stuff would go here
    if (element.clickFX != null) {
      logger.debug(element.clickFX);
      SFX(element.clickFX);
    } else { // Default click case
      SFX('click');
    }

    if (element.sceneToLoad) {
      const config = element.sceneToLoad;
      logger.debug('in HandleClick.js, about to emit loadScene with payload of: ', config);
      store.getState().eventManager.sceneController.sumerian.SystemBus.emit('loadScene', config);
    }

    // ---- SUMERIAN SYSTEM BUS ----
    // If this element is emitting to the Sumerian system bus.
    if (element.sEmit) {
      if (typeof element.sEmit === 'string') {
        let channel = element.sEmit;
        let data = '';

        // We're going to split the emit along the channel and data by using any colons.
        if (element.sEmit.includes(':')) {
          const msg = element.sEmit.split(':');
          channel = msg[0];
          data = msg[1];
          logger.debug(`${channel} ${data}`);
        }

        if (element.params) {
          data = element.params;
        }

        logger.debug('GLOBAL STATE', store.getState().eventManager);
        // store.getState().eventManager.getSceneController().sumerian.SystemBus.emit(channel, data);
        // store.getActions().eventManager.emit({ channel, data });
        store.getState().eventManager.sceneController.sumerian.SystemBus.emit(channel, data);
      } else {
        element.sEmit.forEach((emit) => {
          let channel = emit;
          let data = {};

          // We're going to split the emit along the channel and data by using any colons.
          if (emit.includes(':')) {
            const msg = emit.split(':');
            channel = msg[0];
            data = msg[1];
            logger.debug(`${channel} ${data}`);
          }

          if (element.params) {
            data = element.params;
          }
          logger.debug('GLOBAL STATE', store.getState().eventManager);
          // store.getState().eventManager.getSceneController().sumerian.SystemBus.emit(channel, data);
          // store.getActions().eventManager.emit({ channel, data });
          store.getState().eventManager.sceneController.sumerian.SystemBus.emit(channel, data);
        });
      }
    }

    /*
        _   __  __ ___ _    ___ _____   __  ___ __  __ ___ _____
      /_\ |  \/  | _ \ |  |_ _| __\ \ / / | __|  \/  |_ _|_   _|
      / _ \| |\/| |  _/ |__ | || _| \ V /  | _|| |\/| || |  | |
    /_/ \_\_|  |_|_| |____|___|_|   |_|   |___|_|  |_|___| |_|

                        -- Amplify Emits --
                              (emits)

        This handles all generic emits through amplify's hub.

    */


    // ---- AMPLIFY HUB ----
    // If this element is emitting on click. This is for specifically emitting to the Amplify Hub.
    if (element.emit !== null && element.emit !== undefined) {
      if (typeof element.emit === 'string') {
        let channel = element.emit;
        let data = '';

        // We're going to split the emit along the channel and data by using any colons.
        if (element.emit.includes(':')) {
          const msg = element.emit.split(':');
          // eslint-disable-next-line prefer-destructuring
          channel = msg[0];
          data = msg[1];
          logger.debug(`${channel} ${data}`);
        }

        // -- SPECIAL COMMANDS --
        if (element.emit === 'reload') { // If the emit is simply reload, reload the page when clicked.
          logger.debug('clicked Addison Button, reloading page...');
          window.location.reload();
        } else if (element.emit === 'hide') { // If emit is simply hide, hide the button when clicked.
          const payload = {};
          payload.elements = [];
          payload.elements.push(element);

          Hub.dispatch('hideAmplifyElement', payload);
        } else { // Otherwise, send to hub, like a normal emit.
          Hub.dispatch(channel, data);
        }
      } else {
        // assert(typeof element.emit === 'array');

        element.emit.forEach((emit) => {
          let channel = emit;
          let data = '';

          // We're going to split the emit along the channel and data by using any colons.
          if (emit.includes(':')) {
            const msg = emit.split(':');
            channel = msg[0];
            data = msg[1];
            logger.debug(`${channel} ${data}`);
          }

          // -- SPECIAL COMMANDS --
          if (emit === 'reload') { // If the emit is simply reload, reload the page when clicked.
            logger.debug('clicked Addison Button, reloading page...');
            window.location.reload();
          } else if (emit === 'hide') { // If emit is simply hide, hide the button when clicked.
            const payload = {};
            payload.elements = [];
            payload.elements.push(element);

            Hub.dispatch('hideAmplifyElement', payload);
          } else { // Otherwise, send to hub, like a normal emit.
            Hub.dispatch(channel, data);
          }
        });
      }
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Not recieving element in Element Component. Something has gone wrong.');
  }
};

export default debounce(handleClick, 2500, { leading: true, trailing: false });
